import React from 'react';
import { capitalize } from 'lodash';
import { ErrorBoundary, useSlackInstallStatus, useQueryParams } from '@livingsecurity/shared';

const SlackCallback = () => {
  const { id, error: err } = useQueryParams();
  const { data, isError, error } = useSlackInstallStatus({ id });
  const formattedError = err ? capitalize(err?.replace(/_/g, ' ')) : null;

  return (
    <ErrorBoundary scope="slack-redirect-view">
      <div
        style={{
          width: '100%',
          height: '100vh',
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <img
          src="https://assets.livingsecurity.com/image/upload/v1679943802/logo-vertical_ptamuz_ilzd5d.png"
          alt="Living Security Logo"
          style={{ width: '250px', marginTop: '-100px' }}
        />
        <div style={{ textAlign: 'center' }}>
          {data ? (
            <>
              <h1 style={{ fontSize: '32px', textAlign: 'center' }}>Living Security Training was successfully installed
                on Slack!</h1>
              <p style={{ fontSize: '16px', marginTop: '12px' }}>
                Redirect to the <a href="https://app.livingsecurity.com/account/config">Living Security dashboard</a>
              </p>
            </>
          ) : isError && error?.message ? (
            <>
              <h1 style={{ fontSize: '32px', textAlign: 'center', color: 'red', fontWeight: 600 }}>ERROR</h1>
              <p style={{ fontSize: '16px', marginTop: '12px' }}>{`Reason: ${formattedError || error?.message}`}</p>
              <p style={{ fontSize: '16px', marginTop: '8px' }}>
                Please contact our{' '}
                <a href="https://www.livingsecurity.com/support/contact-support" target="_blank" rel="noreferrer"
                   style={{ color: '#4eb3d4' }}>
                  support team
                </a>{' '}
                if you need assistance
              </p>
            </>
          ) : (
            <>
              <h1 style={{ fontSize: '32px', textAlign: 'center' }}>Installing Living Security Training on Slack...</h1>
              <p style={{ fontSize: '16px', marginTop: '12px' }}>This page will reload automatically</p>
            </>
          )}
        </div>
        <div style={{ fontSize: '10px', color: '#4eb3d4', marginTop: '120px' }}>
          <a href="https://www.livingsecurity.com/data-protection" style={{ fontWeight: 500 }}>
            Privacy Policy
          </a>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default SlackCallback;
