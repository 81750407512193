export const TABLE_CACHE_KEYS = {
  AUDIENCE: 'AUDIENCE',
  AUDIENCES: 'AUDIENCES',
  USERS: 'USERS',
  PARTICIPANTS: 'PARTICIPANTS',
  CAMPAIGNS: 'CAMPAIGNS',
  CAMPAIGN_DETAILS: 'CAMPAIGN_DETAILS',
  QUESTIONS: 'QUESTIONS',
  MANAGE_LIST: 'MANAGE_LIST',
};
