import React from 'react';
import { AuthorizedLayout } from 'components';
import Chatbot from '@livingsecurity/chatbot';

const ChatbotWrapper = ({ standalone }) => {
  return standalone ? (
    <Chatbot embedded />
  ) : (
    <AuthorizedLayout withoutPadding hideSidebar noScroll>
      <Chatbot />
    </AuthorizedLayout>
  );
};

export default ChatbotWrapper;
