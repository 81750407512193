export default {
  users: {
    prop: 'created_at',
    order: -1,
  },
  LSAdmins: {
    prop: 'created_at',
    order: -1,
  },
  campaigns: {
    prop: 'name',
    order: 1,
  },
  questions: {
    prop: 'created_at',
    order: -1,
  },
  companies: {
    prop: 'created_at',
    order: -1,
  },
  history: {
    prop: 'date',
    order: -1,
  },
  audienceParticipants: {
    prop: 'given_name',
    order: -1,
  },
  audiences: {
    prop: 'created',
    order: -1,
  },
  participants: {
    prop: 'lastName',
    order: 1,
  },
  participantsDrilldown: {
    prop: 'dateAssigned',
    order: -1,
  },
  campaignDetails: {
    prop: 'name',
    order: -1,
  },
  feedback: {
    prop: 'contentTitle',
    order: -1,
  },
  policyAcceptance: {
    prop: 'accepted_date',
    order: -1,
  },
  userPolicy: {
    prop: 'title',
    order: -1,
  },
  notificationsHistory: {
    prop: 'date',
    order: -1,
  },
};
