import moment from 'moment-timezone';

export const formatTime = (ms) => {
  const seconds = ms / 1000;

  const mins = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0');
  const secs = (seconds % 60).toString().padStart(2, '0');
  return `${mins}:${secs}`;
};

export const isPastDue = (dueDate) => {
  return moment().isAfter(dueDate);
};

export const CHINESE_LANG = ['zh-Hans', 'zh-Hant'];
export const transformToMomentLocale = (lang) => (CHINESE_LANG.includes(lang) ? 'zh-cn' : lang?.split('-')[0]);

export const formatDate = (date, format, timezone) => {
  moment.locale(transformToMomentLocale(window.locale));
  return timezone ? moment().tz(timezone).format(format || 'L') : moment(date).format(format || 'L');
}
