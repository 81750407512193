import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';
import {
  ContextualHelpModal,
  ErrorBoundary,
  Logo,
  ActionsPanel,
  AppSwitcher,
  CompanyDropdown,
  SearchInput,
  OptionsDropdown,
  URLS,
  USER_GROUPS,
  POSITION,
  useAuth0,
  httpClient,
  TooltipWrapper,
  useChatbotStatus,
  useTrackInteraction,
  FEATURE_TRACK,
} from '@livingsecurity/shared';

import * as Styled from './styles';

/**
 The Navbar component is the top-level component of the application. It contains the logo and actions panel by default and can be extended to include a search input and dashboard items if desired.

 It should always have companyInfo and flags as props. The optional props are for the search input and dashboard items on the participant dashboard.
 */
const NavBar = (props) => {
  const { user, isImpersonate, isUserHasRights, isLsAdmin, isCompanyAdmin } = useAuth0();
  const {
    dashboardItems: DashboardItems,
    companyInfo,
    setCompanyInfo,
    iframeCompanyName,
    dispatch,
    withSearch,
    searchPlaceholder,
    height,
    flags,
    isSticky,
    keepBlue,
    chatSlideoutOpen,
    toggleChatSlideout,
  } = props;
  const { t } = useTranslation('playerDashboard');
  const { pathname } = useLocation();
  const { track } = useTrackInteraction();
  const useIframeName = iframeCompanyName && iframeCompanyName?.toLowerCase() !== companyInfo?.name?.toLowerCase();
  const companyName = useMemo(() => {
    if (useIframeName) {
      return iframeCompanyName;
    }
    return companyInfo?.name || iframeCompanyName;
  }, [companyInfo, iframeCompanyName, useIframeName]);
  const [helpModalIsOpen, setHelpModalIsOpen] = useState(false);
  const [helpContext, setHelpContext] = useState({ main: null, secondary: null });
  const isChatView = pathname.includes(URLS.chatbot);

  const { status: chatbotStatus } = useChatbotStatus({
    tenantId: user.tenant_id,
    queryKey: ['chatbotStatus'],
    enabled: flags.allowChatbot,
  });
  const showChatBtn = useMemo(
    () => (
      flags.allowChatbot &&
      !isChatView &&
      ((!isLsAdmin && isCompanyAdmin) || (isLsAdmin && isImpersonate)) &&
      chatbotStatus === 'enabled'),
    [isLsAdmin, isCompanyAdmin, isImpersonate, isChatView, flags.allowChatbot, chatbotStatus]
  );

  useEffect(() => {
    if (isChatView && chatSlideoutOpen) {
      sessionStorage?.setItem('UNIFY_ASSIST_OPEN', false);
      toggleChatSlideout();
    }
  }, [isChatView, chatSlideoutOpen, toggleChatSlideout]);

  useEffect(() => {
    const fetchHandler = async () => {
      const result = await httpClient.get(`/companies/companies/${user.tenant_id}?tenant_id=${user.tenant_id}`);
      if (result.name !== companyName && setCompanyInfo) dispatch(setCompanyInfo(result));
    };

    if (
      user?.tenant_id &&
      companyInfo?.id !== user?.tenant_id &&
      (isImpersonate ||
        isUserHasRights([USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.TRAINING_DEMO, USER_GROUPS.ENTERPRISE_ADMIN]))
    ) {
      fetchHandler();
    }
  }, [isImpersonate, isUserHasRights, companyInfo, setCompanyInfo, companyName, user.tenant_id, dispatch]);

  const toggleContextualHelp = (main, secondary) => {
    if (main) {
      setHelpContext((prevState) => ({
        ...prevState,
        main,
      }));
    }

    if (secondary) {
      setHelpContext((prevState) => ({
        ...prevState,
        secondary,
      }));
    } else {
      setHelpContext((prevState) => ({
        ...prevState,
        secondary: null,
      }));
    }
    setHelpModalIsOpen(!helpModalIsOpen);
  };

  const isDashboard = window.location.href.indexOf(URLS.dashboard) > -1;

  const trackBtnClick = useCallback((event, metadata = {}) => {
    if (event) track(event, metadata);
  }, [track]);

  const openChatbot = useCallback(() => {
    if (!chatSlideoutOpen) {
      trackBtnClick(FEATURE_TRACK.CHATBOT_VIEW);
      sessionStorage?.setItem('UNIFY_ASSIST_OPEN', true);
    } else {
      sessionStorage?.setItem('UNIFY_ASSIST_OPEN', false);
    }
    toggleChatSlideout();
  }, [chatSlideoutOpen, toggleChatSlideout, trackBtnClick]);

  const ProductName = () => {
    let productName = 'TRAINING';
    if (isDashboard || pathname.includes(URLS.dashboard) || pathname.includes(URLS.player)) {
      return null;
    }
    if (pathname.includes(URLS.teamsBase)) {
      productName = 'CYBERESCAPE ONLINE';
    } else if (pathname.includes(URLS.unify)) {
      productName = 'UNIFY';
    } else if (pathname.includes(URLS.phishing)) {
      productName = 'PHISH';
    } else if (pathname.includes(URLS.support)) {
      productName = 'SUPPORT';
    } else if (pathname.includes(URLS.companies) || pathname.includes(URLS.userManagement)) {
      productName = 'ADMIN';
    } else if (pathname.includes(URLS.chatbot)) {
      productName = 'CHAT';
    }
    return (
      <Styled.ProductName data-testid="navbar-product-name" aria-label="product name">
        {productName}
      </Styled.ProductName>
    );
  };

  return (
    <>
      {helpModalIsOpen && (
        <ContextualHelpModal
          onClose={() => setHelpModalIsOpen(false)}
          mainContent={helpContext.main}
          title={t('navbar-items.badges.contextual-help.title')}
        />
      )}
      <Styled.Wrapper
        keepBlue={keepBlue}
        dashboardView={isDashboard}
        data-testid="header"
        aria-label="header"
        barHeight={height}
        isSticky={isSticky}
      >
        <Styled.LeftContainer>
          {!isDashboard && (isLsAdmin || isCompanyAdmin) && (
            <AppSwitcher trackBtnClick={trackBtnClick} chatEnabled={chatbotStatus === 'enabled'} {...props} />
          )}
          <Logo tenantId={user.tenant_id} reload={companyInfo?.id !== user?.tenant_id} />
          <ProductName />
        </Styled.LeftContainer>
        <ErrorBoundary scope="Navbar">
          {!flags.hideParticipantDashboardSearch && withSearch && (
            <SearchInput
              placeholder={searchPlaceholder}
              onChange={() => console.log('Search needs setting up')}
              width="544px"
              height="48px"
            />
          )}
          <Styled.ItemsWrapper dashboardView={isDashboard} barHeight={height}>
            {DashboardItems && <DashboardItems flags={flags} toggleContextualHelp={toggleContextualHelp} />}

            <>
              {isDashboard && flags.tmpHideLegacyParticipantDashboard ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    height: '57px',
                    position: 'relative',
                    zIndex: 4,
                    color: 'white',
                  }}
                >
                  <OptionsDropdown id="options-menu" user={user} {...props} />
                </div>
              ) : (
                <Styled.RightContainer>
                  {companyName && (isCompanyAdmin || (isLsAdmin && isImpersonate)) && (
                    <TooltipWrapper
                      position={POSITION.RIGHT}
                      content={companyName}
                      showTooltip={useIframeName || companyName?.length > 25}
                    >
                      <CompanyDropdown
                        companyInfo={companyInfo}
                        companyName={companyName}
                        useIframeName={useIframeName}
                      />
                    </TooltipWrapper>
                  )}
                  {showChatBtn && (
                    <Styled.ChatbotButton
                      onClick={openChatbot}
                    >
                      <i className="fad fa-comments-alt" />
                    </Styled.ChatbotButton>
                  )}
                  <ActionsPanel {...props} />
                </Styled.RightContainer>
              )}
            </>
          </Styled.ItemsWrapper>
        </ErrorBoundary>
      </Styled.Wrapper>
    </>
  );
};

NavBar.propTypes = {
  /**
   * Gamification dashboard items to be rendered in the navbar
   * Level, Badges, Rank, and Total Points depending on feature flags status
   */
  dashboardItems: PropTypes.elementType,

  /**
   * Whether to show the assignment search input
   *
   * @default false
   */
  withSearch: PropTypes.bool,

  /**
   * Placeholder text for the assignment search input
   *
   * @default "Search Assignments"
   */
  searchPlaceholder: PropTypes.string,

  /**
   * Height of the navbar in pixels
   *
   * This is used to set the height of the navbar in the legacy view
   *
   * @default ""
   */
  height: PropTypes.string,

  toggleChatSlideout: PropTypes.func,

  /**
   * Feature flags passed from launch darkly service
   * @see https://launchdarkly.com/
   *
   */
  flags: PropTypes.shape({
    hideParticipantDashboardSearch: PropTypes.bool,
    tmpHideLegacyParticipantDashboard: PropTypes.bool,
  }).isRequired,
};

NavBar.defaultProps = {
  dashboardItems: null,
  withSearch: false,
  searchPlaceholder: 'Search Assignments',
  toggleChatSlideout: () => {},
  height: '',
};

export default NavBar;
