import { isNull } from 'lodash';
import { COMMON_ERRORS } from '../../../_constants';
import { hasFalsyValue } from '..';
/**
 * Validate input text object and returns error string
 * @param  {string} value
 * @param  {string} name
 * @param  {number} min=null
 * @param  {number} max=null
 * @param  {boolean} required=false
 *
 * @returns {string} error | ''
 */
export default ({ value, name, min = null, max = null, required = false }) => {
  let error =
    (hasFalsyValue(value) && COMMON_ERRORS.isRequired(name)) ||
    (!isNull(min) && (+value < min) && COMMON_ERRORS.lessThanNumber(name, min)) ||
    (!isNull(max) && (+value > max) && COMMON_ERRORS.moreThanNumber(name, max));
  error = !required && hasFalsyValue(value) ? null : error;
  return error;
};
