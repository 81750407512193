import { useState, useCallback } from 'react';

const usePrompts = ({ user, integrations }) => {
  // TODO: Eventually load from Contentful?
  const [prompts, setPrompts] = useState([
    {
      id: '1',
      name: 'Introductory prompt',
      description: 'Get an overview of what I can do',
      content: 'Hello, what can you help me with?',
      context: 'CHATBOT',
      intent: 'QUESTION',
    },
    {
      id: '2',
      name: 'Report of recent phishing simulations',
      description: 'Get a report of my recent phishing simulations',
      content: 'How did my recent phishing simulations go?',
      context: 'PHISHING',
      intent: 'REPORTS',
    },
    {
      id: '3',
      name: 'The success rate of our latest phishing simulation',
      description: 'Get a report on the success rate of your latest phishing simulations',
      content: 'What was the success rate of our latest phishing simulation?',
      context: 'PHISHING',
      intent: 'REPORTS',
    },
    {
      id: '4',
      name: 'Departments most susceptible to phishing simulations',
      description: 'Get a report on which departments are most susceptible to our phishing simulations',
      content: 'Which departments are most susceptible to our phishing simulations?',
      context: 'PHISHING',
      intent: 'REPORTS',
    },
    {
      id: '5',
      name: 'Departments least susceptible to phishing simulations',
      description: 'Get a report on which departments are least susceptible to our phishing simulations',
      content: 'Which departments are least susceptible to our phishing simulations?',
      context: 'PHISHING',
      intent: 'REPORTS',
    },
    {
      id: '6',
      name: 'Employees most likely to engage with phishing emails',
      description:
        'Get a report on who are the employees most likely to engage with phishing emails in our simulations',
      content:
        'Who are the employees most likely to engage with phishing emails in our simulations? Can we categorize this by role, length of employment, or level of access to sensitive data?',
      context: 'PHISHING',
      intent: 'REPORTS',
    },
    {
      id: '7',
      name: 'Types of phishing emails with the highest success rates',
      description:
        'Get a report on who are the employees least likely to engage with phishing emails in our simulations',
      content: 'What types of phishing emails have the highest success rates in our simulations?',
      context: 'PHISHING',
      intent: 'REPORTS',
    },
  ]);

  const filterPrompts = useCallback(
    (searchTerm) => {
      const result = prompts.filter((prompt) => {
        const searchable =
          prompt.name?.toLowerCase() + ' ' + prompt.description?.toLowerCase() + ' ' + prompt.content?.toLowerCase();
        return searchable.includes(searchTerm.toLowerCase());
      });
      setPrompts(result);
    },
    [prompts, setPrompts],
  );

  return {
    prompts,
    filterPrompts,
  };
};

export default usePrompts;
