import { isObject, union, range as numberRange, keyBy, mapValues, isEmpty } from 'lodash';
import {
  CATALOG_CONTENT_TYPES,
  NUMBERS_RULE,
  INITIAL_LS_SERIES,
  DURATION_KEY,
  INITIAL_LS_CAMPAIGNS,
  INITIAL_LS_CATALOG,
  INITIAL_LS_INDEPENDENT_MODULES,
  INITIAL_MC_INDEPENDENT_MODULES,
  INITIAL_LS_COURSES,
  INITIAL_MC_COURSES,
  INITIAL_MC_SERIES,
  INITIAL_MY_COLLECTION,
  LANGUAGE,
  LOCALE_KEY,
  DURATION_OPTIONS,
} from '../../_constants';

import { cloneObject } from './cloneObject';

const ITEMS_TO_HIDE = [CATALOG_CONTENT_TYPES.CONTENT_IMAGES]; // for now this type of folders are empty.

export const getInitialLocale = (filtersLocales, listOfLocales) => {
  const getLocaleObject = (localeItem) => listOfLocales.find((localeData) => localeData.code === localeItem);

  return filtersLocales.map((item) => {
    const localeItem = getLocaleObject(item);
    return localeItem?.name;
  });
};

const getTransformedName = (item, itemType, index, t) => {
  switch (itemType) {
    case CATALOG_CONTENT_TYPES.QUESTION:
      return t ? t('questions.question-tree-item', { number: index + 1 }) : `Question ${index + 1}`;
    case CATALOG_CONTENT_TYPES.CONTENT_IMAGES:
      return item.fields.internalTitle;
    case CATALOG_CONTENT_TYPES.DOCUMENT:
      return item.fields.displayName || t('document.title');
    default:
      return item.nickname || item.fields.displayName || item.fields.title;
  }
};

export const transformContentStructure = (content, itemCatalogType, t) => {
  let childrenToTransform = content;

  if (!Array.isArray(childrenToTransform)) {
    childrenToTransform = Object.values(content?.fields || {}).reduce((acc, field) => {
      if (
        isObject(field) &&
        (field.fields || (Array.isArray(field) && field.every((item) => isObject(field) && item.fields)))
      ) {
        return acc.concat(field);
      }

      return acc;
    }, []);
  }

  return childrenToTransform.reduce((acc, item, index) => {
    let itemType =
      (item.sys.contentType && item.sys.contentType.sys.id) ||
      (item.sys.type && (item.fields.file || item.fields.file?.fields?.file)) ||
      null;

    if (itemType?.contentType === 'application/pdf') {
      itemType = 'document';
    } else if (itemType?.contentType) {
      // Otherwise we'll get image files showing up in the catalog as well
      itemType = null;
    }

    return itemType && !ITEMS_TO_HIDE.includes(itemType)
      ? acc.concat({
        name: getTransformedName(item, itemType, index, t),
        id: item.sys.id,
        catalogType: itemCatalogType,
        contentType:
          itemType && Object.values(CATALOG_CONTENT_TYPES).includes(itemType)
            ? itemType
            : CATALOG_CONTENT_TYPES.FOLDER,
        rawData: item,
      })
      : acc;
  }, []);
};

export const prepareCatalogFilters = (rawFilters, { locales = [] }) => {
  const localeByName = keyBy(locales, 'name');

  return {
    ...rawFilters,
    duration: rawFilters?.duration?.map((item) => DURATION_OPTIONS[item]) ?? [],
    translations: rawFilters?.translations?.map((tname) => localeByName?.[tname]?.code)?.filter(Boolean) ?? [],
  };
};

export const transformFilterParamsContentful = (params) =>
  Object.entries(params ?? {}).reduce((paramsAcc, [key, value]) => {
    if (!value?.length || key === LANGUAGE || key === LOCALE_KEY) return paramsAcc;
    if (key === DURATION_KEY) {
      const durationArray = value.reduce((durationAcc, range) => {
        const [from, to] = range.match(NUMBERS_RULE).map((number) => +number);
        return union(durationAcc, numberRange(from, to + 1));
      }, []);

      return {
        ...paramsAcc,
        ...(durationArray.length ? { [`fields.${DURATION_KEY}[in]`]: durationArray.join(`,`) } : {}),
      };
    }
    if (key === 'translations') {
      return {
        ...paramsAcc,
        ...(value.length ? { [`fields.${'translations'}[all]`]: value.join(`,`) } : {}),
      };
    }
    return {
      ...paramsAcc,
      [`fields.${key}[in]`]: value.join(`,`),
    };
  }, {});

export const insertChildrenToCatalog = (catalog, path, parentIndex, children, addMore) => {
  try {
    // TODO: investigate how to avoid this clone or at least do it better. With immer.produce for example
    const newCatalog = cloneObject(catalog); //! Not efficient way. Catalog object is pretty big and has a lot of nested large objects.
    let putTo = newCatalog;
    if (path.length > 1) {
      while (path.length > 1) {
        putTo = putTo.children[path.shift()];
      }
    }
    putTo.children[parentIndex].children =
      (addMore ? putTo.children[parentIndex].children.concat(children) : children) || [];
    return newCatalog;
  } catch (error) {
    console.error('failed to insert children to catalog', error);
    return catalog;
  }
};

export const removeChildrenFromCatalog = (catalog, path, id, contentType, isEmptyFilters) => {
  const newCatalog = cloneObject(catalog);
  const indexOfDeleted = newCatalog.children[path].children.findIndex((item) => item.id === id);
  newCatalog.children[path].children = newCatalog.children[path].children.filter((item) => item.id !== id);
  const childrenList = newCatalog.children[path].children;
  if (!childrenList.length && isEmptyFilters) {
    newCatalog.children = newCatalog.children.filter((item) => item.id !== contentType);
  }
  return {
    newCatalog,
    children: childrenList || [],
    indexOfDeleted,
  };
};

export const renameChildrenInCatalog = (catalog, path, id, nickname) => {
  const newCatalog = cloneObject(catalog);
  newCatalog.children[path].children = newCatalog.children[path].children.map((item) => {
    if (item.id === id) item.name = nickname;
    return item;
  });
  return newCatalog;
};

export const getFavoritedFoldersInMyCollections = (list) => {
  if (!Array.isArray(list))
    return {
      ...INITIAL_MY_COLLECTION,
      children: [],
    };
  const favoritedIds = list.reduce((acc, item) => {
    // TODO: remove when BE will fix re-indexing
    const itemId = item?.contentType?.sys?.id || INITIAL_MC_SERIES.id;
    return acc.includes(itemId) ? acc : acc.concat(itemId);
  }, []);
  const myCollectionsContent = [INITIAL_MC_SERIES, INITIAL_MC_INDEPENDENT_MODULES, INITIAL_MC_COURSES];
  const favoriteList = myCollectionsContent.reduce((acc, contentItem) => {
    const selectedItem = favoritedIds.includes(contentItem.id);
    return selectedItem ? acc.concat(contentItem) : acc;
  }, []);
  return {
    ...INITIAL_MY_COLLECTION,
    children: [
      ...favoriteList,
      INITIAL_MC_COURSES,
    ],
  };
};

export const setLsContent = ({ withCampaigns }) => ({
  ...INITIAL_LS_CATALOG,
  children: [INITIAL_LS_SERIES, INITIAL_LS_INDEPENDENT_MODULES, INITIAL_LS_COURSES, ...(withCampaigns ? [INITIAL_LS_CAMPAIGNS] : [])],
});

export const getNudgeText = (plainNudgeText, contentNudge) => {
  if (plainNudgeText === '') return plainNudgeText;

  return plainNudgeText || contentNudge;
};

export const getLocalesMap = (locales = []) =>
  locales?.reduce((acc, locale) => ({ ...acc, [locale.code]: locale }), {}) ?? {};

export const getFiltersInitialData = (fetchedData) => ({
  ...mapValues(fetchedData, () => []),
  duration: [],
  translations: [],
});

export const getTotalEntryDuration = ({ cardType, fields }) => {
  if (isEmpty(cardType) || isEmpty(fields)) return null;
  let duration;
  if (cardType === INITIAL_MC_INDEPENDENT_MODULES.id) {
    duration = fields.duration;
  } else {
    duration = fields.episodes.reduce((acc, episode) => {
      const { fields: episodeFields } = episode;
      return acc + episodeFields?.startVideo?.fields?.duration || 0 + episodeFields?.endVideo?.fields?.duration || 0;
    }, 0);
  }
  return `- ${duration} min${duration !== 1 ? 's' : ''}`;
};

export const getCommonVideoGridFilterParams = (tab) => ({
  ...([INITIAL_LS_SERIES.id, INITIAL_LS_INDEPENDENT_MODULES.id].includes(tab)
    ? {
      content_type: tab,
    }
    : {
      content_type: `${INITIAL_LS_SERIES.id},${INITIAL_LS_INDEPENDENT_MODULES.id}`
    }),
});
