const CONTENT_TYPES = {
  QUESTION: 'question',
  ANSWER: 'answer',
  VIDEO: 'video',
  DOCUMENT: 'document',
  FOLDER: 'folder',
  SERIES: 'series',
  INDEPENDENT_MODULE: 'independentModule',
  CAMPAIGN: 'campaign',
  EPISODE: 'episode',
  PUZZLE: 'puzzle',
  ASSESSMENT: 'assessment',
  TEXT_BLOCK: 'textBlock',
  MODULE: 'embeddedModule',
  CLOUDINARY: 'cloudinaryVideo',
  EMAIL_NOTIFICATION: 'emailNotification',
  CHAT_NOTIFICATION: 'chatNotification',
  MS_TEAMS_NOTIFICATION: 'msTeamsNotification',
  SLACK_NOTIFICATION: 'slackNotification',
};

export default CONTENT_TYPES;
