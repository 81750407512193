import { AUTH_TOKEN } from '../../../_constants';
import jwt_decode from 'jwt-decode';

const LAST_TOKEN_CHECK = 'lastTokenCheck';

export const manageToken = (token = null) => {
  if (typeof token === 'string' && token.length > 1) {
    saveToken(token);
  }
  // removeToken();
};

export const saveToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token);
  // store.dispatch(AuthEntity.actions.setToken(token));
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  window.lsLogout();
};

export const refreshToken = async () => {
  try {
    const token = await window.lsRefreshToken({ ignoreCache: true });
    await window.lsRefreshUser();
    return token;
  } catch (e) {
    console.warn(`[token service] Could not refresh user's auth token: `, e);
  }
};

export const getToken = () => {
  try {
    return localStorage.getItem(AUTH_TOKEN) || null;
  } catch (e) {
    console.error(`[token service] Could not get user's auth token from localStorage: `, e);
    return null;
  }
};

export const getDecodedToken = () => {
  const token = getToken();
  if (!token) {
    return null;
  }
  try {
    return decodeToken(token);
  } catch (e) {
    console.error(`[token service] Could not decode user's auth token: `, e);
    return null;
  }
};

export const decodeToken = (token) => {
  return jwt_decode(token);
};

export const getTokenCheck = () => {
  return localStorage.getItem(LAST_TOKEN_CHECK) || null;
};
export const recordTokenCheck = (checkTime) => {
  localStorage.setItem(LAST_TOKEN_CHECK, JSON.stringify(checkTime));
};

export const startApp = async () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('auth_token') || getToken() || null;
  // if (isNull(token)) {
  //   return store.dispatch(AuthEntity.actions.startWithoutToken());
  // }
  manageToken(token);
  // try {
  //   const { token } = await store.dispatch(AuthEntity.actions.updateToken());
  //   manageToken(token);
  //   return await store.dispatch(AuthEntity.actions.getInitialUserData());
  // } catch (error) {
  //   removeToken();
  //   store.dispatch(AuthEntity.actions.startWithoutToken());
  // }
};

export default {
  removeToken,
  manageToken,
  getToken,
  getDecodedToken,
  refreshToken,
  getTokenCheck,
  recordTokenCheck,
  decodeToken,
  // saveToken,
  // startApp,
};
