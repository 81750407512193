import { useMemo } from 'react';
import { useQuery } from 'react-query';

import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import isUndefined from 'lodash/isUndefined';

import { useAuth0 } from '../lib';
import config from '../config';
import { DEFAULT_SORT, ORDER_TYPE } from '../_constants';
import { convertObjKeysToCamelCase } from '../utils/custom';

export const useCampaignDetailsTable = (
  campaignId,
  queryKey,
  request,
  activeFilter,
  localFilter,
  queryParams = {},
) => {
  const {
    user: { tenant_id: tenantId },
    isImpersonate,
  } = useAuth0();

  const {
    page = 1,
    perPage = config.PARTICIPANT_REPORT_LIST.PAGINATION.PER_PAGE,
    sortBy = DEFAULT_SORT.campaignDetails.prop,
    sortOrder = DEFAULT_SORT.campaignDetails.order,
    query,
    progress,
  } = queryParams;

  const params = queryString.stringify(
    pickBy(
      {
        page,
        per_page: perPage,
        sort: `${sortBy}:${sortOrder > 0 ? ORDER_TYPE.ASC : ORDER_TYPE.DESC}`,
        search_term: query,
        progress: progress === 'all' ? [] : progress,
        ...(isImpersonate ? { tenant_id: tenantId } : {}),
      },
      (item) => !isUndefined(item),
    ),
  );

  const combinedQueryKey = useMemo(() => [queryKey, activeFilter, params], [queryKey, activeFilter, params]);

  const { data, ...rest } = useQuery(
    combinedQueryKey,
    () =>
      request(
        {
          campaignId,
          tenantId,
          filters: localFilter,
        },
        params,
      ),
    {
      staleTime: 5000,
    },
  );
  const formattedData = convertObjKeysToCamelCase(data);

  return {
    data: {
      campaign: {
        audienceType: formattedData?.campaign?.audienceType,
        name: formattedData?.campaign?.name,
        participantCount: formattedData?.campaign?.participantCount,
      },
      participants: formattedData?.campaign?.participants,
      totalPages: Math.ceil(formattedData?.campaign?.participantCount / perPage),
    },
    error: formattedData?.errors,
    message: formattedData?.message,
    ...rest,
  };
};
