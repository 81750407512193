import { useQuery } from 'react-query';
import { httpClient } from '../lib';

const GET_QUERY_KEY = 'GET_USER_CERTIFICATES';

const getUserCertificates = ({ limit = 5, offset = 0, sortField = 'certificate_date', sortOrder = 'desc' }) =>
  httpClient.get(`/certificates/certificates?limit=${limit}&offset=${offset}&sort_field=${sortField}&sort_order=${sortOrder}`);

export const useCertificates = ({ limit, offset, sortField }) => {
  const { data, isLoading, isFetching, isError, isSuccess, error } = useQuery([GET_QUERY_KEY, offset], () =>
    getUserCertificates({
      limit,
      offset,
      sortField,
    }),
    {
      keepPreviousData: true,
    }
  );
  return {
    ...(data || {}),
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
  };
};
