import { httpClient, useAuth0, USER_GROUPS } from '@livingsecurity/shared';

// Query Keys
export const CompanyIntegrations = {
  get: () => [{ name: `chatbot.integrations.all` }],
};

export const getIntegrations = async (tenantId, isUserHasRights, isImpersonate) => {
  if (!tenantId) return;

  let slack, msTeams, phishing;
  const trainingLsAdmin = isUserHasRights([USER_GROUPS.LS_ADMIN]) && isImpersonate;
  const trainingAdmin = isUserHasRights([USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN]);
  const teamsAdmin = isUserHasRights([USER_GROUPS.TEAMS_CUSTOMER_ADMIN, USER_GROUPS.TEAMS_COLLABORATOR]);
  const phishAdmin = isUserHasRights([USER_GROUPS.PHISHING_CUSTOMER_ADMIN]);
  const unifyAdmin = isUserHasRights([USER_GROUPS.UNIFY_CUSTOMER_ADMIN, USER_GROUPS.UNIFY_CUSTOMER_LIMITED_ADMIN]);

  try {
    const slackInt = await getSlackIntegration(tenantId);
    slack = slackInt?.status === 'enabled' && slackInt?.hasInstallations;
  } catch (e) {
    console.error(e);
  }
  try {
    const teamsInt = await getMsTeamsIntegration(tenantId);
    msTeams = teamsInt?.apps?.[0]?.status === 'enabled';
  } catch (e) {
    console.error(e);
  }
  try {
    const phishingInt = await getPhishingIntegration(tenantId);
    phishing = phishingInt?.status === 'active' && phishAdmin;
  } catch (e) {
    console.error(e);
  }

  return {
    slack,
    msTeams,
    phishing,
    training: trainingAdmin || trainingLsAdmin,
    teams: teamsAdmin,
    unify: unifyAdmin,
    support: true,
  };
};

export const getSlackIntegration = async (tenantId) => {
  if (!tenantId) return;
  return httpClient.get(`/slack-native/slack/integration?tenant_id=${tenantId}`);
};

export const getMsTeamsIntegration = async (tenantId) => {
  if (!tenantId) return;
  return httpClient.get(`/connections/connections/apps?tenant_id=${tenantId}`);
};

export const getPhishingIntegration = async (tenantId) => {
  if (!tenantId) return;
  return httpClient.get(`/phishing/phishing/integration?tenant_id=${tenantId}`);
};
