import React from 'react';
import { useAuth0 } from '@livingsecurity/shared';

/*
 * App wrapper that routes users to our various application routes
 */
const ChatLayout = ({ children }) => {
  return (
    <>
      <div id="chat-layout" className="flex mt-px">
        <main className="flex flex-col w-full overflow-hidden">{children}</main>
      </div>
    </>
  );
};

export default ChatLayout;
