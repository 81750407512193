import CATALOG_TYPES from './CATALOG_TYPES';

export { default as CATALOG_TYPES } from './CATALOG_TYPES';
export { default as CATALOG_CONTENT_TYPES } from './CONTENT_TYPES';
export { default as QUESTION_FORM } from './QUESTION_FORM';
export { default as ANSWERS } from './ANSWERS';
export { default as FILTER_FIELDS } from './FILTER_FIELDS';
export { default as ENTRY_STATUS } from './ENTRY_STATUS';
export { default as CATALOG_COMMON } from './CATALOG_COMMON';

export const STEPS_TO_PARENT_ENTITY = 3;
export const DURATION_KEY = 'duration';
export const LOCALE_KEY = 'locale';
export const LANGUAGE = 'language';
export const CATALOG_PER_PAGE = 35;
export const CATALOG_INFINITY_COLUMN_HEIGHT = 1120;
export const CATALOG_PER_PAGE_FULL_PAGE = 200;
export const COPY_TREE_POLLING_INTERVAL = 5000;
export const REQUIRED_CONTENT = ['episodes', 'assessment', 'video', 'puzzle'];

export const COPY_STATUSES = {
  CREATED: 'created',
  PROCESSING: 'processing',
  FAILED: 'failed',
  DONE: 'done',
};

export const INITIAL_LS_INDEPENDENT_MODULES = {
  name: 'Independent Modules',
  catalogType: CATALOG_TYPES.LS,
  id: 'independentModule',
  isTopLevel: true,
};

export const INITIAL_LS_COURSES = {
  name: 'Courses',
  catalogType: CATALOG_TYPES.LS,
  id: 'courses',
  isTopLevel: true,
};

export const INITIAL_LS_CATALOG = {
  name: 'LS Catalog',
  catalogType: CATALOG_TYPES.LS,
  isRoot: true,
  children: [],
};

export const INITIAL_LS_CAMPAIGNS = {
  name: 'Campaign Templates',
  catalogType: CATALOG_TYPES.LS,
  id: 'campaign',
  isTopLevel: true,
};

export const INITIAL_MC_SERIES = {
  name: 'Series',
  catalogType: CATALOG_TYPES.MY,
  id: 'series',
  isTopLevel: true,
};

export const INITIAL_MC_INDEPENDENT_MODULES = {
  name: 'Independent Modules',
  catalogType: CATALOG_TYPES.MY,
  id: 'independentModule',
  isTopLevel: true,
};

export const INITIAL_MC_COURSES = {
  name: 'Courses',
  catalogType: CATALOG_TYPES.MY,
  id: 'courses',
  isTopLevel: true,
};

export const INITIAL_MY_COLLECTION = {
  name: 'My Collection',
  catalogType: CATALOG_TYPES.MY,
  isRoot: true,
  children: [
    // TODO: uncomment when ready
    //   {
    //   name: 'Email Notification',
    //   catalogType: CATALOG_TYPES.MY,
    //   id: 'emailNotification',
    //   isTopLevel: true,
    // }, {
    //   name: 'Slack Notification',
    //   catalogType: CATALOG_TYPES.MY,
    //   id: 'slackNotification',
    //   isTopLevel: true,
    // }
  ],
};

export const MODAL_COPY_STATUSES = {
  MAKE_A_COPY: 'MAKE_A_COPY',
  CUSTOMIZE: 'CUSTOMIZE,',
};

export const initContentFilterParams = {
  [CATALOG_TYPES.LS]: {},
  [CATALOG_TYPES.MY]: {},
};

export const TAB_VALUES = {
  list: 'list',
  videos: 'videos',
};

export const VIDEO_GRID_TAB_VALUES = {
  all: 'all',
  series: 'series',
  independentModule: 'independentModule',
};

export const LMS_PACKAGE_TYPES = {
  SCORM_12: 'scorm12',
  SCORM_2004_3RD: 'scorm2004_3rd',
  TINCAN: 'tincan',
  CMI5: 'cmi5',
  AICC: 'aicc',
};

export const CAMPAIGN_FILTER_FIELDS = {
  categories: 'categories',
  concepts: 'concepts',
  subscriptions: 'subscriptions',
};

export const DURATION_OPTIONS = {
  '1 min': '0-1',
  '2-4 mins': '2-4',
  '< 5 mins': '0-5',
  '6-10 mins': '6-10',
  '11-15 mins': '11-15',
  '16-20 mins': '16-20',
  '21-30 mins': '21-30',
  '31-45 mins': '31-45',
  '46-90 mins': '46-90',
  '< 90 mins': '0-90',
};

export const FILTER_DELTA_POSITION = {
  TOP: 95,
  LEFT: 16,
};

export const FILTER_TYPE = {
  DURATION: 'duration',
  SUBSCRIPTIONS: 'subscriptions',
  LOCALE: 'locale',
  LANGUAGE: 'language',
  TRANSLATIONS: 'translations',
};

export const POPOVER_STYLES = {
  overflow: 'visible',
  position: 'relative',
  zIndex: 9999,
};

export const TRANSLATIONS = [
  { code: 'en-US', name: 'English (United States)' },
  { code: 'ja', name: 'Japanese' },
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'zh-Hans', name: 'Chinese (Simplified)' },
  { code: 'zh-Hant', name: 'Chinese (Traditional)' },
  { code: 'fr-CA', name: 'French (Canada)' },
  { code: 'tr-TR', name: 'Turkish (Turkey)' },
  { code: 'pt-BR', name: 'Portuguese (Brazil)' },
];

export const INCLUDED_VALUES = ['title', 'points', 'concepts', 'categories', 'difficulty', 'targetedAudiences', 'type'];
