import { startCase } from 'lodash';

const isRequired = (name) => `The ${startCase(name)} is required`;
const isNotValid = (name) => `The ${startCase(name)} is not valid`;
const lessThan = (name, count) => `The ${startCase(name)} must be no less than ${count} symbols`;
const lessThanNumber = (name, count) => `The ${startCase(name)} must be no less than ${count}`;
const moreThan = (name, count) => `The ${startCase(name)} must be no more than ${count} symbols`;
const moreThanNumber = (name, count) => `The ${startCase(name)} must be no more than ${count}`;
const duplicateAnswer = 'This answer is duplicated';
const exist = (name) => `This ${name} already exist`;

export default {
  isRequired,
  isNotValid,
  lessThan,
  lessThanNumber,
  moreThan,
  moreThanNumber,
  exist,
  duplicateAnswer,
};
