import { useState, useMemo, useCallback } from 'react';
import { useQuery } from 'react-query';
import { orderBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { ChatConversations, fetchUsersConversations } from '../handlers/conversations';

const useConversation = ({ user }) => {
  const [selectedConversation, setActiveConversation] = useState({ conversationId: uuidv4() });

  const {
    data,
    isLoading: isConversationLoading,
    refetch,
  } = useQuery(
    ChatConversations.get({ userId: user?.email }),
    () => fetchUsersConversations(user?.email, user?.tenant_id),
    {
      enabled: !!user?.email,
      onSuccess: (conversationData) => orderBy(conversationData?.conversations, ['updatedAt'], ['desc']),
    },
  );

  const userConversations = useMemo(() => {
    return orderBy(data?.conversations, ['updatedAt'], ['desc']);
  }, [data?.conversations]);

  return {
    activeConversation: selectedConversation?.messages ? selectedConversation : userConversations[0] || {},
    userConversations,
    isConversationLoading,
    setActiveConversation,
    refetchConversations: refetch,
  };
};

export default useConversation;
