import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'react-use';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button, BUTTON_TYPES, tokenService } from '@livingsecurity/shared';

import * as Styled from './styles';

const LogoutWarning = ({ teamsRoute, phishingRoute }) => {
  const { t } = useTranslation('shared');
  const [modalVisible, toggleModal] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(60);
  const { enableSessionTimeoutModal } = useFlags();

  useInterval(
    () => {
      checkTokenExpiration();
    },
    enableSessionTimeoutModal ? 1000 : 30000,
  );

  const checkTokenExpiration = useCallback(() => {
    const token = tokenService.getDecodedToken();
    if (token?.exp) {
      const diff = moment(token.exp * 1000).diff(moment());
      if (diff / 1000 < 30 && !modalVisible) {
        setSecondsRemaining(Math.ceil(diff / 1000));
        if (enableSessionTimeoutModal && !teamsRoute && !phishingRoute) {
          toggleModal(true);
        } else {
          handleRefreshToken();
        }
      }
    }
  }, [modalVisible, enableSessionTimeoutModal, teamsRoute, phishingRoute]);

  const handleRefreshToken = async () => {
    console.log('Refreshing user session');
    await tokenService.refreshToken();
    toggleModal(false);
  };

  const handleLogout = () => {
    tokenService.removeToken();
  };

  return (
    modalVisible && (
      <Styled.Backdrop>
        <Styled.Modal>
          <Styled.ModalHeader>{t('session-timeout-modal.title')}</Styled.ModalHeader>
          <Styled.ModalBody>{t('session-timeout-modal.body')}</Styled.ModalBody>
          <Styled.ButtonContainer>
            <Button onClick={handleLogout}>{t('session-timeout-modal.logout')}</Button>
            <Button variant={BUTTON_TYPES.SKY} onClick={handleRefreshToken}>
              {t('session-timeout-modal.stay-logged-in')} (
              <Timer startingTime={secondsRemaining} callback={handleLogout} />)
            </Button>
          </Styled.ButtonContainer>
        </Styled.Modal>
      </Styled.Backdrop>
    )
  );
};

LogoutWarning.propTypes = {};

export default LogoutWarning;

const Timer = ({ startingTime, callback }) => {
  const [delay, setDelay] = useState(1000);
  const [secondsRemaining, setSecondsRemaining] = useState(startingTime);

  useInterval(() => {
    if (secondsRemaining > 0) {
      setSecondsRemaining(secondsRemaining - 1);
    } else {
      setDelay(null);
      callback();
    }
  }, delay);

  return <span>{secondsRemaining}</span>;
};
