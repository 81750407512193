import React, { useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import createAuth0Client from '@auth0/auth0-spa-js';
import Cookies from 'js-cookie';
import {
  ErrorBoundary,
  Loader,
  URLS,
  Iframe,
  TEAMS_URLS,
  getTeamsManagementUrl,
  getTeamsPermalinkFromRoute,
  useAuth0,
  getBaseHostname,
} from '@livingsecurity/shared';

const TeamsParticipants = ({ history }) => {
  const { pathname, search } = useLocation();
  const teamsPublicUrls = useMemo(
    () => [
      URLS.teamsBooking,
      URLS.teamsCancelBooking,
      URLS.teamsEditBooking,
      URLS.teamsBookingResult,
      URLS.teamsBookingCancel,
      URLS.teamsLeaderboard,
      // we want to check either the legacy URLs (above) or the new ones that include /teams (below)
      TEAMS_URLS.teamsBooking,
      TEAMS_URLS.teamsCancelBooking,
      TEAMS_URLS.teamsEditBooking,
      TEAMS_URLS.teamsBookingResult,
      TEAMS_URLS.teamsBookingCancel,
      TEAMS_URLS.teamsLeaderboard,
    ],
    [],
  );

  useEffect(() => {
    if (window.location?.href?.includes('livingsecurity.com')) {
      window.document.domain = 'livingsecurity.com';
    }
  }, []);

  useEffect(() => {
    if (!pathname.includes(URLS.teamsBase)) {
      history.replace(`${URLS.teamsBase}${pathname}${search}`);
    }
  }, [pathname, history, search]);

  const getEmbeddedUrl = useCallback(() => {
    if (!teamsPublicUrls.includes(pathname)) {
      return pathname.includes(URLS.teamsBase)
        ? `${getTeamsManagementUrl().view}${pathname.split(URLS.teamsBase)[1]}`
        : `${getTeamsManagementUrl().view}${pathname}`;
    }
    return `${getTeamsManagementUrl().view}${getTeamsPermalinkFromRoute(pathname, window.location.href)}${search}`;
  }, [pathname, search, teamsPublicUrls]);

  // ---------------
  const { isAuthenticated, loading, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const saveToken = async () => {
      const result = await getIdTokenClaims();
      if (result) {
        const { __raw: token } = result;
        Cookies.set('lsToken', token, {
          domain: window.location.hostname === 'localhost' ?
            window.location.hostname :
            `.${getBaseHostname(window.location.hostname)}`,
          sameSite: 'Lax',
        });
      }
    };

    if (isAuthenticated) {
      saveToken();
      return;
    }

    const loginWithRedirect = async () => {
      const pathnameParts = pathname.split('/');
      /*
      * Save "appState" "targetUrl" (same as "window.location.href")
      * for correct "Auth0" redirect, because during "loading" state in
      * "App.js" global "Auth0Provider" redirects on combined
      * "window.location?.origin" with our location, that breaks
      * "URL" and "Router" redirect on default page depends on
      * user group.
      */
      const targetUrl = `${pathnameParts[pathnameParts.length - 1]}${search}`;

      const auth0 = await createAuth0Client({
        domain: process.env.REACT_APP_AUTH_DOMAIN || '',
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID || '',
        redirect_uri: window.location.href,
      });

      await auth0.loginWithRedirect({
        appState: { targetUrl },
        connection: 'email',
      });
    };

    if (!pathname.includes('/ranking')) {
      loginWithRedirect();
    }
  }, [isAuthenticated, pathname, search, getIdTokenClaims]);
  // ---------------

  return pathname.includes('/ranking') || isAuthenticated ? (
    <ErrorBoundary scope="teams-participant-booking">
      <Iframe
        id="teams-embed-participant-booking"
        allow="clipboard-read; clipboard-write *;"
        className="embedded-iframe"
        data-testid="teams-embed-participant-booking"
        title="Living Security CyberEscape Online Booking"
        src={getEmbeddedUrl()}
      />
    </ErrorBoundary>
  ) : <Loader coverElement />;
};

TeamsParticipants.propTypes = {
  history: PropTypes.any.isRequired,
};

export default TeamsParticipants;
