import { stringify } from 'query-string';
import { flatten, partition, map, difference, uniq, keyBy } from 'lodash';
import { BUILDER_MODAL_TYPES, CATALOG_TYPES, CATALOG_CONTENT_TYPES } from '../../_constants';
import { isTraining } from './isTraining';

const NOTIFICATION_FIELDS = [
  BUILDER_MODAL_TYPES.ASSIGNMENT,
  BUILDER_MODAL_TYPES.DUE_DATE,
  BUILDER_MODAL_TYPES.PAST_DUE,
];

const CONTENT_TYPES = [CATALOG_CONTENT_TYPES.SERIES, CATALOG_CONTENT_TYPES.INDEPENDENT_MODULE];
const NOTIFICATION_TYPES = [CATALOG_CONTENT_TYPES.EMAIL_NOTIFICATION];

const compactBy = (arr = [], mapFn = (item) => item) =>
  arr.reduce((acc, ...rest) => {
    const newItem = mapFn(...rest);
    if (!newItem) return acc;
    acc.push(newItem);
    return acc;
  }, []);

export const fetchEntries = async ({
  types,
  ids,
  catalogType,
  locale,
  getContentfulEntries,
  getCollectionEntries,
  alreadyUsedIds,
}) => {
  const isLs = catalogType === CATALOG_TYPES.LS;

  const realIds = difference(uniq(ids), Array.from(alreadyUsedIds));

  const response = await Promise.all(
    types.map((type) => {
      const fetchMethod = isLs ? getContentfulEntries : getCollectionEntries;

      return fetchMethod({
        id: type,
        params: isLs ? { 'sys.id[in]': realIds.join(`,`), locale } : stringify({ ids: realIds }),
      }).then((responseItem) => {
        const items = responseItem[isLs ? 'items' : 'result'];
        items.forEach((item) => alreadyUsedIds.add(item.sys.id));
        return items;
      });
    }),
  );
  return flatten(response);
};

export const getNotificationsContent = async ({
  dataToTransform,
  customNotifications,
  MSNotifications,
  SlackNotifications,
  loadEntries,
}) => {
  const customNotificationsMap = keyBy(customNotifications ?? [], 'id');
  const MSNotificationMap = keyBy(MSNotifications ?? [], 'id');
  const SlackNotificationMap = keyBy(SlackNotifications ?? [], 'id');
  const allIds = dataToTransform.reduce(
    (acc, timelineBlock) =>
      isTraining(timelineBlock)
        ? acc.concat(NOTIFICATION_FIELDS.map((key) => timelineBlock[key].deliveries)).flat()
        : acc.concat(timelineBlock.content_item_ids.map((id) => ({ id, is_lscatalog: false }))),
    [],
  );

  const [catalogNotificationIds, collectionNotificationIds] = partition(allIds, 'is_lscatalog').map((n) =>
    map(n, 'id'),
  );

  const collectionNotifications = compactBy(
    collectionNotificationIds,
    (notificationId) => customNotificationsMap[notificationId],
  );

  const contentfulNotifications =
    catalogNotificationIds && catalogNotificationIds.length > 0
      ? await loadEntries({ types: NOTIFICATION_TYPES, ids: catalogNotificationIds, catalogType: CATALOG_TYPES.LS })
      : [];

  const MSNotificationsContent = compactBy(
    catalogNotificationIds,
    (notificationId) => MSNotificationMap[notificationId],
  );

  const SlackNotificationsContent = compactBy(
    catalogNotificationIds,
    (notificationId) => SlackNotificationMap[notificationId],
  );

  const notificationsContent = [...contentfulNotifications, ...collectionNotifications].map((n) => ({
    ...n,
    id: n?.id || n?.sys?.id,
    title: n?.title || n?.fields?.title,
    contentType: n?.sys?.contentType?.sys?.id || CATALOG_CONTENT_TYPES.EMAIL_NOTIFICATION,
  }));

  const MSNotificationsContentFormatted = MSNotificationsContent.map((n) => ({
    ...n,
    id: n?.id || n?.sys?.id,
    title: n?.title || n?.fields?.title,
    contentType: CATALOG_CONTENT_TYPES.MS_TEAMS_NOTIFICATION,
  }));

  const SlackNotificationsContentFormatted = SlackNotificationsContent.map((n) => ({
    ...n,
    id: n?.id || n?.sys?.id,
    title: n?.title || n?.fields?.title,
    contentType: CATALOG_CONTENT_TYPES.SLACK_NOTIFICATION,
  }));

  return keyBy(
    [...notificationsContent, ...MSNotificationsContentFormatted, ...SlackNotificationsContentFormatted],
    ({ id, contentType }) => `${id}-${contentType}`,
  );
};

export const getContent = async ({ dataToTransform, loadEntries }) => {
  const contentIds = dataToTransform.reduce((acc, item) => acc.concat(item.content_item_ids), []);
  if (!contentIds.length) return [];

  // We should call both API's, because we can't indicate which content is from My Collection and which is from LS
  const contentPromises = [CATALOG_TYPES.MY, CATALOG_TYPES.LS].map((catalogType) =>
    loadEntries({ types: CONTENT_TYPES, ids: contentIds, catalogType }),
  );

  const rawContent = (await Promise.allSettled(contentPromises))
    .filter((p) => p.status === 'fulfilled')
    .map((p) => p.value)
    .flat();

  const content = rawContent.map((c) => ({
    ...c,
    id: c?.id || c?.sys?.id,
    title: c?.nickname || c?.fields?.title,
    contentType: c?.sys?.contentType?.sys?.id,
  }));

  const contentMap = keyBy(content, 'id');

  return contentMap;
};

export const formatTimelineStructure = async ({
  getContentfulEntries,
  getCollectionEntries,
  dataToTransform,
  returnOnlyData = false,
  customNotifications,
  MSNotifications,
  SlackNotifications,
  locale,
}) => {
  if (!getContentfulEntries)
    return console.warn('getContentfulEntries method is required for formatTimelineStructure util');
  if (!dataToTransform) return console.warn('dataToTransform is required for formatTimelineStructure util');
  if (!Array.isArray(dataToTransform))
    return console.warn('dataToTransform should be array in formatTimelineStructure util');
  if (typeof returnOnlyData !== 'boolean')
    return console.warn('returnOnlyData should be boolean in formatTimelineStructure util');

  const alreadyUsedIds = new Set();

  const loadEntries = ({ types, ids, catalogType }) =>
    fetchEntries({ types, ids, catalogType, locale, getContentfulEntries, getCollectionEntries, alreadyUsedIds });

  const contentMap = await getContent({ dataToTransform, loadEntries });

  const notificationsContentMap = await getNotificationsContent({
    dataToTransform,
    customNotifications,
    MSNotifications,
    SlackNotifications,
    loadEntries,
  });

  const timelineBlocks = dataToTransform.map((item) => {
    const transformedData = {
      data: {
        ...item,
        ...NOTIFICATION_FIELDS.reduce(
          (acc, notificationType) => ({
            ...acc,
            [notificationType]: {
              ...item[notificationType],
              content: compactBy(
                item[notificationType]?.deliveries,
                ({ id, delivery_type }) => notificationsContentMap[`${id}-${delivery_type}`],
              ),
            },
          }),
          {},
        ),
        content: compactBy(item.content_item_ids, (contentId) =>
          isTraining(item) ? contentMap[contentId] : notificationsContentMap[`${contentId}-emailNotification`],
        ),
      },
      isOpen: true,
    };
    return returnOnlyData ? transformedData.data : transformedData;
  });

  return timelineBlocks;
};
