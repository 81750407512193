import React, { lazy } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { LDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { datadogRum } from '@datadog/browser-rum';
import { isEmpty } from 'lodash';

import {
  ErrorBoundary,
  GlobalStyleWithLocation,
  i18n,
  LazyLoad,
  Loader,
  ModalProvider,
  TEAMS_URLS,
  URLS,
  useAuth0,
  USER_GROUPS,
  SearchParamsProvider,
} from '@livingsecurity/shared';
import StandalonePlayer from '@livingsecurity/content-viewer';
import { Actions, Home, LoginLayout, PrivateRoute } from 'components';

import ApiAuthentication from 'containers/ApiAuthentication';
import Audience from 'containers/Audience';
import CampaignBuilder from 'containers/CampaignBuilder';
import Catalog from 'containers/Catalog';
import Chatbot from 'containers/Chatbot';
import Analytics from 'containers/Analytics';
import UserManagement from 'containers/UserManagement';
import Companies from 'containers/Companies';
import LSAdmins from 'containers/LSAdmins';
import Config from 'containers/Config';
import { TeamsManagement, TeamsParticipants } from 'containers/TeamsManagement';
import UnifyDashboard from 'containers/UnifyDashboard';
import PhishingDashboard from 'containers/PhishingDashboard';
import SupportGarden from 'containers/Support';
import ContentExport from 'containers/ContentExport';
import SlackCallback from 'containers/Onboarding/SlackCallback';
import { Welcome, Register, Loading as OnboardLoading } from 'containers/SelfSignUp';

import { history, store } from './lib';

import 'react-dates/initialize';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@livingsecurity/shared/dist/assets/styles/react-toastify.scss';
import '@livingsecurity/shared/dist/assets/styles/react-datepicker.scss';
import '@livingsecurity/shared/dist/assets/styles/react-table.scss';
import '@livingsecurity/cyberpuzzles/dist/styles/index.css';
import '@livingsecurity/cyberblocks/dist/styles/app.css';
import '@livingsecurity/reporting/dist/assets/styles/index.css';
import '@livingsecurity/chatbot/dist/output.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const ContentViewer = lazy(() => import('containers/ContentViewer'));
const JitAssignmentLoader = lazy(() => import('containers/JitAssignmentLoader'));
const ParticipantDashboard = lazy(() => import('containers/ParticipantDashboard'));
const Profile = lazy(() => import('containers/Profile'));

const LazyContentViewer = (props) => <LazyLoad component={ContentViewer} {...props} />;
const LazyJitAssignmentLoader = (props) => <LazyLoad component={JitAssignmentLoader} {...props} />;
const LazyParticipantDashboard = (props) => <LazyLoad component={ParticipantDashboard} {...props} />;
const LazyProfile = (props) => <LazyLoad component={Profile} {...props} />;

const LDConsumer = ({ children }) => {
  const flags = useFlags();
  return children({ flags });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // With SSR, we usually want to set some default staleTime
      // above 0 to avoid re-fetching immediately on the client
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      retry: false, // after getting error disable auto-retry
    },
  },
});

function App() {
  const { loading, isAuthenticated, user, userGroups } = useAuth0();
  const adminRoles = [
    USER_GROUPS.LS_ADMIN,
    USER_GROUPS.CUSTOMER_ADMIN,
    USER_GROUPS.ENTERPRISE_ADMIN,
    USER_GROUPS.TRAINING_DEMO,
    USER_GROUPS.TEAMS_COLLABORATOR,
    USER_GROUPS.TEAMS_CUSTOMER_ADMIN,
    USER_GROUPS.TEAMS_LS_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_ADMIN,
    USER_GROUPS.UNIFY_CUSTOMER_LIMITED_ADMIN,
    USER_GROUPS.PHISHING_CUSTOMER_ADMIN,
    USER_GROUPS.PHISHING_LS_ADMIN,
  ];

  if (loading) {
    return <Loader coverElement />;
  }

  const ldUser =
    isAuthenticated && !isEmpty(user)
      ? {
        kind: 'user',
        key: user.sub,
        email: user.email,
        custom: {
          tenantId: user.tenant_id,
          userGroups,
          createdAt: user.created_at,
          updatedAt: user.updated_at,
          loginCount: user.logins_count,
          blocked: user.blocked,
          metadata: user.user_metadata,
        },
      }
      : undefined;

  return (
    <ModalProvider>
      <LDProvider
        clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_KEY}
        context={ldUser}
        deferInitialization
        options={{
          inspectors: [{
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key, detail) => {
              datadogRum?.addFeatureFlagEvaluation(key, detail.value);
            },
          }],
        }}
      >
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
              <div className="App" id="App">
                <ErrorBoundary>
                  <Router history={history}>
                    <SearchParamsProvider
                      queryStringOptions={{ arrayFormat: 'bracket-separator', arrayFormatSeparator: '|' }}
                    >
                      <GlobalStyleWithLocation />
                      <LDConsumer>
                        {({ flags }) => (
                          <Switch>
                            <Route path="/" component={Home} exact />
                            <Route path={URLS.actions} component={Actions} exact />
                            <Route path={URLS.login} component={LoginLayout} />
                            <Route path={URLS.welcome} component={Welcome} exact />
                            <Route path={URLS.register} component={Register} exact />
                            <Route path={URLS.onboardLoading} component={OnboardLoading} exact />
                            <Route
                              path={URLS.player}
                              render={(routeProps) => (
                                <StandalonePlayer {...routeProps} ldUser={ldUser} userFlags={flags || {}} />
                              )}
                            />
                            <Route
                              path={URLS.passwordlessAssignment}
                              render={(routeProps) => (
                                <StandalonePlayer {...routeProps} ldUser={ldUser} userFlags={flags || {}} />
                              )}
                            />
                            <Route path={URLS.jitAssignment} component={LazyJitAssignmentLoader} />
                            <PrivateRoute path={URLS.dashboard} component={LazyParticipantDashboard} />
                            <PrivateRoute
                              path={URLS.assignment}
                              groups={[
                                USER_GROUPS.LS_ADMIN,
                                USER_GROUPS.CUSTOMER_ADMIN,
                                USER_GROUPS.ENTERPRISE_ADMIN,
                                USER_GROUPS.PARTICIPANT,
                              ]}
                              component={LazyContentViewer}
                            />
                            <PrivateRoute
                              path={URLS.userManagement}
                              groups={[USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN]}
                              component={UserManagement}
                            />
                            <PrivateRoute
                              path={[
                                URLS.configuration,
                                URLS.config,
                                URLS.policyAcceptance,
                                URLS.account,
                                URLS.userProvisioning,
                                URLS.userDomains,
                                URLS.configCampaigns,
                                URLS.userPolicy,
                                URLS.customNotifications,
                                URLS.notifications,
                              ]}
                              groups={[USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN]}
                              component={Config}
                            />

                            <PrivateRoute
                              path={[URLS.catalog, URLS.catalogList, URLS.catalogPreview]}
                              groups={[
                                USER_GROUPS.LS_ADMIN,
                                USER_GROUPS.CUSTOMER_ADMIN,
                                USER_GROUPS.ENTERPRISE_ADMIN,
                                USER_GROUPS.TRAINING_DEMO,
                              ]}
                              component={Catalog}
                            />
                            <PrivateRoute
                              path={URLS.campaignBuilder}
                              groups={[USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN]}
                              component={CampaignBuilder}
                            />
                            <PrivateRoute
                              path={URLS.audiences}
                              groups={[USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN]}
                              component={Audience}
                            />
                            <PrivateRoute
                              path={[
                                URLS.campaignDetails,
                                URLS.feedback,
                                URLS.training,
                                URLS.participants,
                                URLS.campaigns,
                                URLS.questions,
                              ]}
                              groups={[USER_GROUPS.LS_ADMIN, USER_GROUPS.CUSTOMER_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN]}
                              component={Analytics}
                            />
                            <PrivateRoute path={URLS.profile} component={LazyProfile} />
                            <PrivateRoute
                              path={[URLS.companies, URLS.companyEdit]}
                              groups={[USER_GROUPS.LS_ADMIN, USER_GROUPS.ENTERPRISE_ADMIN]}
                              component={Companies}
                            />
                            <PrivateRoute path={URLS.lsAdmins} groups={[USER_GROUPS.LS_ADMIN]} component={LSAdmins} />
                            <Redirect exact from={URLS.configuration} to={URLS.account} />

                            {/* Blanket Redirect for anything /risk related */}
                            <Redirect exact from={URLS.risk} to={URLS.campaigns} />

                            {/* CHATBOT */}
                            {flags?.allowChatbot && (
                              <PrivateRoute path={URLS.chatbot} groups={adminRoles} component={Chatbot} />
                            )}

                            {/* PLATFORM SHARED */}
                            <PrivateRoute path={URLS.apiAuthentication} groups={adminRoles} component={ApiAuthentication} />

                            {/* UNIFY */}
                            <PrivateRoute path={URLS.unify} groups={adminRoles} component={UnifyDashboard} />

                            {/* TEAMS MANAGEMENT */}
                            <Route
                              path={[
                                URLS.teamsBooking,
                                URLS.teamsCancelBooking,
                                URLS.teamsEditBooking,
                                URLS.teamsBookingResult,
                                URLS.teamsBookingCancel,
                                URLS.teamsLeaderboard,
                                TEAMS_URLS.teamsBooking,
                                TEAMS_URLS.teamsCancelBooking,
                                TEAMS_URLS.teamsEditBooking,
                                TEAMS_URLS.teamsBookingResult,
                                TEAMS_URLS.teamsBookingCancel,
                                TEAMS_URLS.teamsLeaderboard,
                              ]}
                              component={TeamsParticipants}
                            />
                            <PrivateRoute path={URLS.teamsBase} groups={adminRoles} component={TeamsManagement} />

                            {/* PHISHING */}
                            <PrivateRoute
                              path={[URLS.phishing, URLS.phishingDemo]}
                              groups={adminRoles}
                              component={PhishingDashboard}
                            />

                            {/* SUPPORT GARDEN */}
                            <PrivateRoute path={URLS.support} groups={adminRoles} component={SupportGarden} />

                            <PrivateRoute
                              path="/content-team"
                              groups={[USER_GROUPS.LS_ADMIN]}
                              component={ContentExport}
                            />

                            <Route path={URLS.slackCallback} component={SlackCallback} />
                            <Redirect to="/" />
                          </Switch>
                        )}
                      </LDConsumer>
                    </SearchParamsProvider>
                  </Router>
                </ErrorBoundary>
              </div>
              <ToastContainer newestOnTop limit={5} />
              {!process.env.REACT_APP_HIDE_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
          </I18nextProvider>
        </Provider>
      </LDProvider>
    </ModalProvider>
  );
}

export default App;
