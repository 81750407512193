import React, { useRef, useMemo } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

export const PromptMenu = ({ prompts, filterPrompts, promptTrigger }) => {
  const { t } = useTranslation('chatbot');
  const filterRef = useRef();

  const handleFilter = useMemo(
    () =>
      debounce(() => {
        filterPrompts(filterRef.current.value);
      }, 500),
    [filterRef],
  );

  return (
    <div className="w-full flex flex-col items-start mt-3">
      <div className="w-full px-2">
        <input
          type="text"
          ref={filterRef}
          placeholder={t('menu.search-prompts')}
          className="input input-sm p-3 input-bordered w-full"
          onKeyUp={handleFilter}
        />
      </div>
      <div className="mt-3 w-full">
        {prompts.map((prompt, index) => (
          <div
            key={index}
            className="flex py-1 px-2 hover:bg-white/10 cursor-pointer"
            onClick={() => promptTrigger(prompt, true)}
          >
            • {prompt.content}
          </div>
        ))}
      </div>
    </div>
  );
};
