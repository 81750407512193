import NAVIGATION_ACTIONS from './NAVIGATION_ACTIONS';

export default {
  base: '/',
  actions: '/actions/:action',
  logout: `/actions/${NAVIGATION_ACTIONS.LOG_OUT}`,
  cancelImpersonate: `/actions/${NAVIGATION_ACTIONS.CANCEL_IMPERSONATE}`,
  login: '/login',
  welcome: '/welcome',
  register: '/register',
  onboardLoading: '/onboard-loading',
  slackCallback: '/slack/callback',
  dashboard: '/dashboard',
  assignment: '/assignment/:id',
  jitAssignment: '/assignment/new',
  userManagement: '/user-management',
  configuration: '/configuration',
  knowledgeBase: 'https://www.livingsecurity.com/support/training-platform',
  teamsSupport: 'https://www.livingsecurity.com/support/teams-platform',
  phishSupport: 'https://www.livingsecurity.com/support/phishing-simulator-tool',
  unifySupport: 'https://www.livingsecurity.com/support',
  supportDesk: 'https://www.livingsecurity.com/support',
  community: 'https://community.livingsecurity.com',
  dataProtection: 'https://www.livingsecurity.com/data-protection',

  config: '/config',
  policyAcceptance: '/config/policy-acceptance',
  account: '/config/account',
  userProvisioning: '/config/user-provisioning',
  userDomains: '/config/user-domains',
  configCampaigns: '/config/campaigns',
  samlConfig: '/config/samlConfig',
  userPolicy: '/config/user-policy',
  customNotifications: '/config/custom-notifications',

  chatbot: '/chat',

  catalog: '/catalog',
  catalogList: '/catalog/list',
  catalogPreview: '/catalog/preview',
  campaignBuilder: '/campaign-builder/:type?/:id?',
  audiences: '/audiences/:type?/:id?',
  notifications: '/notifications',
  emailNotifications: '/notifications/email',
  notificationHistory: '/notifications/history',

  campaigns: '/campaigns',
  campaignDetails: '/campaigns/:id',
  participants: '/participants',
  feedback: '/feedback',
  training: '/training',
  risk: '/risk',
  questions: '/questions',

  userPage: '/user-page',
  profile: '/profile',
  companies: '/companies',
  companyEdit: '/companies/:id',
  lsAdmins: '/ls-admins',
  externalApi: '/external-api',
  player: '/player',
  passwordlessAssignment: '/player/:id',

  // Teams Participant Routes
  teamsBooking: '/accept-invite',
  teamsCancelBooking: '/cancel-booking',
  teamsEditBooking: '/edit-booking',
  teamsBookingResult: '/booking-result/:result/:id?',
  teamsBookingCancel: '/booking-result/canceled',

  // Teams Public Routes
  teamsLeaderboard: '/ranking/:id',

  // Platform Shared Routes
  apiAuthentication: '/admin/api-auth',

  // Apps
  teamsBase: '/teams',
  unify: '/unify',
  phishing: '/phishing',
  phishingDemo: '/phishing/demo',
  support: '/support',
};
