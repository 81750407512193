export const getPlayerCoverImage = (imageData) => {
  if (!imageData?.fields) return null;
  const {
    title,
    description,
    file: { url },
  } = imageData.fields;
  return {
    description,
    title,
    // use "endsWith" method in future or regex
    coverImage: url ? ((url?.includes('.jpg') || url?.includes('.png')) ? `https:${url}` : `https:${url}.jpg`) : null,
  };
};
