import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PromptVariableModal = ({ prompt, variables, embedded, onSubmit, onClose }) => {
  const { t } = useTranslation('chatbot');
  const [updatedVariables, setUpdatedVariables] = useState(
    variables
      .map((variable) => ({ key: variable, value: '' }))
      .filter((item, index, array) => array.findIndex((t) => t.key === item.key) === index),
  );

  const modalRef = useRef(null);
  const nameInputRef = useRef(null);

  const handleChange = (index, value) => {
    setUpdatedVariables((prev) => {
      const updated = [...prev];
      updated[index].value = value;
      return updated;
    });
  };

  const handleSubmit = () => {
    if (updatedVariables.some((variable) => variable.value === '')) {
      alert(`${t('fill-all-variables')}`);
      return;
    }

    onSubmit(updatedVariables.map((variable) => variable.value));
    onClose();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [onClose]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  return (
    <div
      className={`${
        embedded ? 'absolute' : 'fixed'
      } z-[9999] inset-0 top-[-65px] bottom-[-110px] flex items-center justify-center bg-black bg-opacity-60`}
      onKeyDown={handleKeyDown}
    >
      <div
        ref={modalRef}
        className="max-h-[400px] w-4/5 max-w-xl my-8 transform overflow-y-auto flex flex-col items-center p-2 text-left transition-all bg-neutral border border-primary/30 rounded"
        role="dialog"
      >
        <div className="mb-3 text-xl font-bold text-black dark:text-neutral-200">{prompt.name}</div>

        <div className="mb-3 text-sm italic text-black dark:text-neutral-200">{prompt.description}</div>

        {updatedVariables.map((variable, index) => (
          <div className="mb-1 w-4/5" key={index}>
            <div className="mb-1 text-sm font-bold text-neutral-200">{`${variable.key}:`}</div>
            <textarea
              ref={index === 0 ? nameInputRef : undefined}
              className="mt-1 w-full px-2 py-1 text-slate-200 bg-neutral border border-primary/10 rounded"
              style={{ resize: 'none' }}
              placeholder={t('enter-value', { key: variable.key })}
              value={variable.value}
              onChange={(e) => handleChange(index, e.target.value)}
              rows={1}
            />
          </div>
        ))}

        <button
          className="btn btn-sm btn-primary mt-3 mx-auto px-2 py-1 focus:outline-none text-white"
          onClick={handleSubmit}
        >
          {t('set-variables')}
        </button>
      </div>
    </div>
  );
};
