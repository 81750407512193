import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import qs from 'query-string';
import { merge } from 'lodash';
import { Auth0Provider, AUTH_TOKEN, config, httpClient, IMPERSONATE } from "@livingsecurity/shared";
import { history } from './lib';
import App from './App';

import 'react-markdown-editor-lite/lib/index.css';
import '@livingsecurity/shared/dist/assets/styles/fontawesome-all.min.css';
import '@livingsecurity/shared/dist/assets/styles/reset.scss';
import '@livingsecurity/shared/dist/assets/styles/fonts.scss';
import '@livingsecurity/shared/dist/assets/styles/base.scss';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  const { search, pathname } = window.location;
  const { campaignId, delivery_type } = qs.parse(search, merge({ parseBooleans: true }));
  if(appState && localStorage.getItem(IMPERSONATE.JWT_REFRESH_EXPIRATION)){
    localStorage.removeItem(IMPERSONATE.JWT_REFRESH_EXPIRATION)
  }
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : `${pathname}?campaignId=${campaignId}&delivery_type=${delivery_type}`,
  );
};

const getRedirectUri = () => {
  if (window.location?.pathname.includes('/dashboard')) {
    return window.location?.href;
  }
  return window.location?.origin;
};

// updating user data in elastic search
const onAuthenticated = (user, token) => {
  window.ldClient?.waitUntilReady().then(() => {
    const flags = window.ldClient.allFlags();

    if (user.tenant_id && !flags['disable-elasticsearch-update']) {
      httpClient
        .put(
          `/users/users/${encodeURI(user.sub)}?tenant_id=${user.tenant_id}`,
          {
            given_name: user.given_name,
            family_name: user.family_name,
            name: `${user.given_name} ${user.family_name}`,
            picture: user.picture,
          },
          {
            headers: {
              [AUTH_TOKEN]: `Token ${token}`,
            },
          },
        )
        .catch((e) => {
          // console.error(e);
        });
    }
  });
};

/**
 * This is for mocking backend responses with msw, uncomment the code below to enable the service worker.
 *
 * You'll find the mock files by app domain in the `./mocks` folder.
 */
const { worker } = require('./mocks/browser');

if (window.location.hostname === 'localhost') {
  // worker.start();
}

ReactDOM.render(
  <Auth0Provider
    domain={config.AUTH_CONFIG.domain}
    client_id={config.AUTH_CONFIG.clientId}
    redirect_uri={getRedirectUri()}
    // audience={config.AUTH_CONFIG.audience}
    onRedirectCallback={onRedirectCallback}
    onAuthenticated={onAuthenticated}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
);
