import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useAuth0, Iframe, showErrorsFromBackend, Loader } from '@livingsecurity/shared';

import { ContentViewerEntity } from '../../_entities';


const SCORM_FIELDS = {
  ACCURACY: 'cmi.core.score.raw',
  PROGRESS: 'cmi.core.lesson_location',
  SUSPEND_DATA: 'cmi.suspend_data',
};

const scormDataModel = {
  cmi: {
    core: {
      student_id: '',
      student_name: '',
      lesson_location: '',
      lesson_status: '',
      score: {
        raw: '',
        scaled: '',
        min: '',
        max: '',
      },
      credit: '',
      entry: '',
      total_time: null,
      lesson_mode: '',
      exit: '',
      session_time: new Date().getTime(),
    },
    suspend_data: '',
    launch_data: '',
    comments: '',
    comments_from_lms: '',
    student_data: {
      mastery_score: 0,
      max_time_allowed: 0,
      time_limit_action: ''
    },
    student_preference: {
      audio: 0,
      language: 'English',
      speed: 0,
      text: 0
    },
    interactions: [],
    objectives: [],
    completion_status: 'incomplete',
    success_status: '',
    location: '2',
  },
};

const Scorm = ({
  url,
  onComplete,
}) => {
  const {
    user: { tenant_id: tenantId, name },
  } = useAuth0();
  const dispatch = useDispatch();
  const {
    params: { id: assignmentId },
  } = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    'cmi.core.student_id': tenantId,
    'cmi.core.student_name': name,
  });

  const { loadScormProgress, saveScormProgress } = ContentViewerEntity.actions;

  useEffect(() => {
    const getProgress = async () => {
      try {
        const { scorm_data: scormData} = await dispatch(loadScormProgress(assignmentId));

        setData(prev => ({
          ...prev,
          ...scormData,
        }));
      } catch (e) {
        if (e.response?.status === 404) {
          await saveScormProgress(assignmentId, {
            scorm_data: data,
          });
        } else {
          showErrorsFromBackend(e);
        }
      } finally {
        setLoading(false);
      }
    };

    getProgress();
  }, []);

  const saveProgress = async (newData) => {
    try {
      await dispatch(saveScormProgress(assignmentId, {
        scorm_data: {
          ...newData,
        },
      }));
    } catch(e) {
      showErrorsFromBackend(e);
    };
  };


  // SCORM 1.1 and SCORM 1.2
  // LMSInitialize( '' ) : bool
  // LMSFinish( '' ) : bool
  // LMSGetValue( element : CMIElement ) : string
  // LMSSetValue( element : CMIElement, value : string) : string
  // LMSCommit( '' ) : bool
  // LMSGetLastError() : CMIErrorCode
  // LMSGetErrorString( errorCode : CMIErrorCode ) : string
  // LMSGetDiagnostic( errocCode : CMIErrorCode ) : string

  /* eslint-disable no-multi-assign */
  window.API = window.API_1484_11 = {};

  // minimum required method for SCORM package
  window.API.LMSInitialize = window.API_1484_11.Initialize = () => {
    console.log('Initialize');
    return "true";
  };

  // minimum required method for SCORM package
  window.API.LMSFinish = window.API_1484_11.Terminate = () => {
    console.log('LMSFinish / Ternimate');
    onComplete();
    return "true";
  };

  window.API.LMSGetValue = window.API_1484_11.GetValue = (model) => {
    console.log('GetValue', {
      model,
      value: data[model] || '',
      data,
    });
    return data[model] || '';
  };

  window.API.LMSSetValue = window.API_1484_11.SetValue = async (model, value) => {
    console.log('SetValue', {
      model,
      value,
      data,
    });
    setData(prev => ({
      ...prev,
      [model]: value,
    }))

    if (SCORM_FIELDS.ACCURACY === model) {
      dispatch(ContentViewerEntity.actions.updateScormAccuracy(value));
    }

    if (SCORM_FIELDS.PROGRESS === model) {
      dispatch(ContentViewerEntity.actions.updateScormProgress(value));
    }

    if (SCORM_FIELDS.SUSPEND_DATA === model) {
      saveProgress({
        ...data,
        [model]: value,
      });
    }

    return "true";
  };

  window.API.LMSCommit = window.API_1484_11.Commit = () => {
    return "true";
  };

  window.API.LMSGetLastError = window.API_1484_11.GetLastError = () => {
    return "0";
  };

  window.API.LMSGetErrorString = window.API_1484_11.GetErrorString = (errorCode) => {
    return "No error";
  };

  window.API.LMSGetDiagnostic = window.API_1484_11.GetDiagnostic = (errorCode) => {
    return "No error";
  };

  const init = () => {
    window.API.LMSInitialize();
    window.API_1484_11.Initialize();
  };

  return loading ? <Loader /> : (
    <Iframe
      id="custom-scorm-module"
      title="Custom SCORM Module"
      src={url}
      onLoad={init}
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        backgroundColor: 'white',
      }}
    />
  );
};

Scorm.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Scorm;
