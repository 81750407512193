import styled from 'styled-components';

export const Backdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999999;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 200px;
  left: calc(50% - 225px);
  background: white;
  border-radius: 10px;
  padding: 10px;
  z-index: 999999999;
  width: 450px;
  height: 220px;
`;

export const ModalHeader = styled.div`
  font-size: 1.5rem;
  padding: 4px 0;
  border-bottom: 1px gray solid;
  font-weight: 600;
`;

export const ModalBody = styled.div`
  font-size: 0.9rem;
  margin-top: 10px;
  padding: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 10px;
`;
