import React from 'react';

export const PromptList = ({ prompts, activePromptIndex, onSelect, onMouseOver, promptListRef }) => {
  return (
    <ul
      ref={promptListRef}
      className="z-10 max-h-[160px] w-4/5 overflow-scroll py-1 mx-auto rounded shadow-xl bg-cyan-dark text-white"
    >
      {prompts.map((prompt, index) => (
        <li
          key={prompt.id}
          className={`${
            index === activePromptIndex ? 'bg-white/5 text-primary' : ''
          } cursor-pointer py-1 px-2 text-sm text-white`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSelect();
          }}
          onMouseEnter={() => onMouseOver(index)}
        >
          {prompt.name}
        </li>
      ))}
    </ul>
  );
};
