import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const exportZipFile = ({ file, fileName = 'file', base64Convert }) => {
  if (base64Convert) {
    file = b64toBlob(file);
  } else {
    file = new Blob([file], { type: 'application/zip' });
  }

  const downloadUrl = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', `${fileName}.zip`);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

const b64toBlob = (b64Data, sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: 'application/zip' });
}

export const generateZipFile = ({ files, folderName }) => {
  const zip = new JSZip();

  const folder = zip.folder(folderName);

  files.forEach((f, i) => {
    folder.file(f.fileName || `${i}.vtt`, f.file, { base64: true });
  });

  zip.generateAsync({type:"blob"}).then((content) => {
    saveAs(content, `${folderName}.zip`);
  });
}
