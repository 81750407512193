import { useQuery } from 'react-query';

import { getIntegrations } from '../handlers/integrations';
import { CompanyIntegrations } from '../handlers/integrations';

const useIntegrations = (tenantId, isUserHasRights, isImpersonate) => {
  const {
    data,
    isLoading: integrationsLoading,
    refetch,
  } = useQuery(
    CompanyIntegrations.get(),
    () => getIntegrations(tenantId, isUserHasRights, isImpersonate),
    {
      enabled: !!tenantId,
    },
  );

  return {
    integrations: data,
    integrationsLoading,
    refetchConversations: refetch,
  };
};

export default useIntegrations;
