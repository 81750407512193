import { useQuery } from 'react-query';

import { clientContentful } from '../lib';
import { contentfulUtils, getMediaValue, getPlayerCoverImage } from '../utils/custom';
import { CONTENT_TYPES } from '../_constants';

const getContentfulEntry = ({ contentId = '', contentfulConfig = {} }) =>
  clientContentful.getEntry(contentId, contentfulConfig);

export const useDetailsModal = ({ contentId = '', contentfulConfig = {}, t }) => {
  const { data, isLoading, isError } = useQuery(
    ['details-modal-query', contentId],
    () => getContentfulEntry({ contentId, contentfulConfig }),
    {
      enabled: !!contentId,
    },
  );
  const contentType = data?.sys?.contentType?.sys?.id;
  const image = data?.fields?.images?.fields?.hero || data?.fields?.coverImage;
  const coverImage = getPlayerCoverImage(image)?.coverImage;

  const content = {
    id: data?.id,
    title: data?.fields?.displayName || data?.fields?.heading || data?.fields?.title,
    description: data?.fields?.outwardDescription || data?.fields?.description,
    difficulty: data?.fields?.difficulty,
    points: data?.fields?.points,
    cardBackground: getMediaValue(data?.fields?.coverImage)?.url,
    sectionDuration: contentfulUtils.getTotalEntryDuration({ cardType: contentType, fields: data?.fields }),
    contentData: contentfulUtils.getContentData(data?.sys?.contentType?.sys?.id, data?.fields),
    contentLabel: t(
      `content-card.content-type.${contentType === CONTENT_TYPES.SERIES ? 'episodes' : 'independent-module'}`,
    ),
    contentType,
    coverImage,
    scormUrl: data?.fields?.scormUrl,
    moreInfoUrl: data?.fields?.moreInfoUrl,
    fields: data?.fields
  };

  return {
    content,
    videoData: contentfulUtils.getVideoDetails(contentType, data?.fields),
    isLoading,
    isError,
  };
};
