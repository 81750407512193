import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ErrorBoundary,
  ErrorReload,
  Iframe,
  Loader,
  PHISH_URLS,
  useAuth0,
  useLSPhishing,
} from '@livingsecurity/shared';

import { AuthorizedLayout } from 'components';

const PhishingDashboard = ({ history }) => {
  const { locale } = useAuth0();
  const { loading, hasAccess, getEmbeddedUrl } = useLSPhishing();
  const [iframeLoading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const embedUrl = getEmbeddedUrl();

  useEffect(() => {
    if (window.location?.href?.includes('livingsecurity.com')) {
      window.document.domain = 'livingsecurity.com';
    }
  }, []);

  useEffect(() => {
    if (!loading && !iframeLoading && !hasAccess && window.location?.pathname !== PHISH_URLS.demo) {
      history.replace(PHISH_URLS.demo);
    }
  }, [hasAccess, loading, history, iframeLoading]);

  const onLoad = useCallback(() => {
    if (!loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3500);
    }
  }, [loading]);

  const onError = useCallback((error) => {
    /* eslint no-console: "off" */
    console.error(error);
    setError(true);
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload(true);
  }, []);

  if (!locale) {
    return null;
  }

  return (
    <AuthorizedLayout app="PHISHING" withBottomPaddingForHubspot>
      <>
        {!hasError && (loading || iframeLoading) && <Loader />}
        {hasError ? (
          <ErrorReload callback={refreshPage} />
        ) : (
          embedUrl && (
            <ErrorBoundary scope="phishing-dashboard">
              <Iframe
                id="phish-embed-frame"
                allow="clipboard-read; clipboard-write *;"
                className="embedded-iframe"
                data-testid="phish-embed-frame"
                title="Living Security Phish"
                src={embedUrl}
                onLoad={onLoad}
                onError={onError}
                style={{
                  opacity: `${iframeLoading ? 0 : 1}`,
                  transition: 'opacity 1s',
                }}
              />
            </ErrorBoundary>
          )
        )}
      </>
    </AuthorizedLayout>
  );
};

PhishingDashboard.propTypes = {
  history: PropTypes.any.isRequired,
};

export default PhishingDashboard;
