import styled from 'styled-components';
import { Page as StyledPage } from 'react-pdf';
import { ButtonStyles, BREAKPOINTS } from '@livingsecurity/shared';

export const Page = styled(StyledPage)`
  canvas {
    height: calc(100vh - 150px) !important;
    width: auto !important;
  }

  @media (max-width: ${BREAKPOINTS.PHONE}) {
    min-width: 420px;
    width: 420px !important;
    margin: 0;
    overflow-x: auto;

    canvas {
      min-width: 420px;
      width: 420px !important;
      height: auto !important;
    }
  }

  @media (max-height: 500px) and (orientation: landscape) {
    min-width: 100vw;
    width: 100vw !important;
    height: 100vh !important;
    margin: 0;
    overflow-x: auto;

    canvas {
      height: auto !important;
      min-width: 100vw;
      width: 100vw !important;
    }
  }
`;

export const SlideshowContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  margin: 0 auto 16px;
  background: white;

  @media (max-width: ${BREAKPOINTS.PHONE}), (max-height: ${BREAKPOINTS.PHONE}) and (orientation: landscape) {
    margin: 0;
    overflow-x: auto;
  }
`;

export const NotificationContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;

  ${ButtonStyles.ButtonWrapper} {
    font-size: 24px;
    font-weight: bold;
    width: 235px;
    height: 65px;
    text-transform: uppercase;
    background-color: var(--dodge-blue-two);
  }

  @media (max-width: ${BREAKPOINTS.PHONE}), (max-height: ${BREAKPOINTS.PHONE}) and (orientation: landscape) {
    ${ButtonStyles.ButtonWrapper} {
      font-size: 14px;
      width: 120px;
      height: 36px;
    }
  }
`;

export const NotificationInfo = styled.div`
  color: #000000;
  margin-right: 32px;
`;

export const NotificationNextContent = styled.div`
  font-size: 24px;
`;
