import styled from 'styled-components';
import { BREAKPOINTS } from '@livingsecurity/shared';

export const TextBlockContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 30px 50px;

  @media (max-width: ${BREAKPOINTS.PHONE}), (max-height: ${BREAKPOINTS.PHONE}) and (orientation: landscape) {
    padding: 30px 8px;
  }
`;
