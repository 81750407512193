import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextBlock as TextBlockComponent } from '@livingsecurity/shared';
import { LmsService } from '../../utils/lmsUtils';
import * as Styled from './styles';

const TextBlock = ({ heading, content, onComplete, loading }) => {
  const { t } = useTranslation('contentViewer');
  return (
    <Styled.TextBlockContainer>
      <TextBlockComponent
        heading={heading}
        content={content}
        loading={loading}
        onComplete={onComplete}
        LmsService={LmsService}
      />
    </Styled.TextBlockContainer>
  );
};

TextBlock.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape()),
  onComplete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

TextBlock.defaultProps = {
  heading: '',
  content: {},
  loading: false,
};

export default TextBlock;
