import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getLinkWithParams, showErrorsFromBackend } from '../utils/custom';
import { notification } from '../utils/services';
import { useToggle, useAddToCampaign } from '../hooks';
import { URLS } from '../_constants';

export const useAddToCampaignFromCatalog = ({ platformCampaignGuid, isImpersonate, tenantId }) => {
  const history = useHistory();
  const { t } = useTranslation('catalog');
  const [isAddToCampaignModalOpen, toggleAddToCampaignModal] = useToggle(false);

  const { mutate: addContentfulCampaignToCampaign, isLoading: isAddCampaignLoading } = useAddToCampaign({
    onSuccess: (data) => {
      const { id } = data;

      toggleAddToCampaignModal();
      history.push(getLinkWithParams(URLS.campaignBuilder, { type: 'add', id }));

      notification.success(t('messages.success.add', { ns: 'campaignBuilder' }));
    },
    onError: () => {
      toggleAddToCampaignModal();

      notification.error(t('messages.error.add', { ns: 'campaignBuilder' }));
    },
  });

  const handleAddCampaign = useCallback(
    async (name, test) => {
      try {
        await addContentfulCampaignToCampaign({
          name,
          test,
          campaignGuid: platformCampaignGuid,
          ...(isImpersonate ? { tenantId } : {}),
        });
      } catch (error) {
        showErrorsFromBackend(error);
      }
    },
    [addContentfulCampaignToCampaign, platformCampaignGuid, isImpersonate, tenantId],
  );
  return {
    isAddToCampaignModalOpen,
    toggleAddToCampaignModal,
    handleAddCampaign,
    isAddCampaignLoading,
  };
};
