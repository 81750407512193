import { useQuery, useMutation } from 'react-query';
import { httpClient } from '../lib';

const getChatbotStatus = ({ tenantId = '' }) =>
  httpClient.get(`/chatbot/chat/integration${tenantId ? `?tenant_id=${tenantId}` : ''}`);

const updateChatbotStatus = ({ tenantId = '', body = {} }) =>
  httpClient.post(`/chatbot/chat/integration${tenantId ? `?tenant_id=${tenantId}` : ''}`, body);

export const useChatbotStatus = ({ tenantId = '', queryKey = [], enabled }) => {
  const { data, isLoading, isError, isSuccess, error } = useQuery(queryKey, () => getChatbotStatus({ tenantId }), {
    enabled,
  });
  return {
    service: data?.service,
    status: data?.status,
    acceptedTerms: data?.acceptedTerms,
    isStatusLoading: isLoading,
    isStatusError: isError,
    isStatusSuccess: isSuccess,
    statusError: error,
  };
};

export const useUpdateChatbotStatus = (mutationOptions = {}) => {
  return useMutation(({ tenantId = '', body = {} }) => {
    return updateChatbotStatus({ tenantId, body });
  }, mutationOptions);
};
