import React from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { useTranslation } from 'react-i18next';
import { setFeedback } from '../../api/handlers/conversations';
import { ChatBubble } from '../atoms';

export const ChatList = ({
  conversationId,
  messages,
  scrollViewRef,
  user,
  loading,
  feedback,
  isConversationLoading,
  embedded,
  regenerateFromMsg,
}) => {
  const { t } = useTranslation('chatbot');
  const leaveFeedback = (feedbackType, index) => {
    datadogLogs.logger.info(`${user.email} left ${feedbackType} feedback for:`);
    datadogLogs.logger.info(`User Message: `, messages[index - 1].content);
    datadogLogs.logger.info(`Assistant Message: `, messages[index].content);

    const message = messages[index];
    const userId = user.email;
    const tenantId = user.tenant_id;

    if (conversationId && userId && tenantId && feedbackType && message) {
      setFeedback({
        conversationId,
        userId,
        tenantId,
        feedback: feedbackType,
        message,
      });
    }
  };

  return (
    <>
      {!messages.length && !isConversationLoading && (
        <div className="absolute top-[50px] text-center left-0 right-0 flex flex-col justify-center items-center p-4 sm:px-6 lg:px-8 lg:py-8 mx-auto">
          <h1 className="text-4xl font-bold text-gray-800 sm:text-5xl dark:text-white">{t('title')}</h1>
          <p className="mt-3 text-label text-gray-600 dark:text-gray-400">{t('subtitle')}</p>
        </div>
      )}

      <ul className="space-y-2 pt-4 pb-4 px-1 md:px-2 overflow-x-hidden" style={{ minHeight: `calc(100vh - 130px)` }}>
        {messages?.map(({ content, role }, index) => {
          const fb = feedback?.find((f) => f?.message?.content === content);
          return (
            <ChatBubble
              key={index}
              message={content}
              avatarUrl={role === 'assistant' ? 'https://platform-cdn.livingsecurity.com/chat/logo.png' : user?.picture}
              timestamp={new Date().toString()}
              position={role === 'assistant' ? 'start' : 'end'}
              botLoading={role === 'assistant' && loading && index === messages.length - 1}
              embedded={embedded}
              feedback={fb}
              regenerateFromMsg={() => regenerateFromMsg(index)}
              leaveFeedback={(feedbackType) => leaveFeedback(feedbackType, index)}
            />
          );
        })}
        <div ref={scrollViewRef} className="h-[80px]" />
      </ul>
    </>
  );
};
