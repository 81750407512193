import React from 'react';
import { useTranslation } from 'react-i18next';

export const PromptSuggestions = ({ promptTrigger }) => {
  const { t } = useTranslation('chatbot');
  return (
    <div className="w-full backdrop-blur-lg bottom-[70px] z-10 absolute p-3 flex justify-end">
      <div
        className="badge badge-outline px-3 py-2 w-auto cursor-pointer hover:bg-primary/20"
        onClick={() => promptTrigger({ content: t('prompts.phishing-simulation-effectiveness') })}
      >
        {t('prompts.phishing-simulation-effectiveness')}
      </div>
    </div>
  );
};
