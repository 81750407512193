import { isEmpty } from 'lodash';
import { CONTENT_TYPES } from '../../_constants';
import { getMediaValue } from './getMediaValue';
import { getPlayerCoverImage } from './getPlayerCoverImage';

const findDeepBy = (items, fn) => items?.flatMap(fn)?.filter(Boolean)?.at(0) ?? null;

const LOCAL_CONTENT_FIELDS = {
  START_VIDEO: 'startVideo',
  END_VIDEO: 'endVideo',
  MODULE: 'embeddedModules',
};

export const parsePuzzleContent = (item) => {
  if (!item) return null;
  return {
    id: item?.sys?.id,
    contentType: item?.sys?.contentType?.sys?.id,
    title: item?.fields?.title,
    displayName: item?.fields?.displayName || item?.fields?.title,
    duration: item?.fields?.duration,
    puzzleUrl: item?.fields?.puzzleUrl,
    puzzleContent: {
      ...(item?.fields ?? {}),
    },
  };
};

export const parseVideoContent = (item) => {
  if (!item) return null;
  const image = item?.images?.fields?.hero || item?.fields?.coverImage;
  const coverImage = getPlayerCoverImage(image)?.coverImage;
  return {
    ...item?.fields,
    id: item?.sys?.id,
    coverImage: item?.fields?.coverImage ? `https:${getMediaValue(item?.fields?.coverImage)?.url}` : coverImage,
    contentType: item?.sys?.contentType?.sys?.id,
    title: item?.fields?.title,
    displayName: item?.fields?.displayName || item?.fields?.title,
    duration: item?.fields?.duration,
    publicId: getMediaValue(item?.fields?.videoFile?.[0], 'videoFile')?.publicID,
    captionFile: item?.fields?.captionFile,
    downloadable: item?.fields?.downloadable,
  };
};

export const parseAssessmentContent = (item) => {
  if (!item) return null;
  return {
    id: item?.sys?.id,
    contentType: item?.sys?.contentType?.sys?.id,
    title: item?.fields?.title,
    displayName: item?.fields?.displayName || item?.fields?.heading || item?.fields?.title,
    questions: item?.fields?.questions,
  };
};

export const parseTextBlockContent = (item) => {
  if (!item) return null;
  return {
    id: item?.sys?.id,
    contentType: item?.sys?.contentType?.sys?.id,
    title: item?.fields?.heading,
    displayName: item?.fields?.heading,
    content: item?.fields?.content,
  };
};

export const parseDocumentContent = (item) => {
  if (!item) return null;
  return {
    id: item?.sys?.id,
    contentType: CONTENT_TYPES.DOCUMENT,
    title: item?.fields?.title,
    displayName: item?.fields?.displayName || item?.fields?.heading || item?.fields?.title,
    file: item?.fields?.file,
  };
};

const parsersByContentTypeMap = {
  [CONTENT_TYPES.PUZZLE]: parsePuzzleContent,
  [CONTENT_TYPES.VIDEO]: parseVideoContent,
  [CONTENT_TYPES.ASSESSMENT]: parseAssessmentContent,
  [CONTENT_TYPES.TEXT_BLOCK]: parseTextBlockContent,
  [CONTENT_TYPES.DOCUMENT]: parseDocumentContent,
};

export const parseFieldsByContentType = ({ fields }) => {
  return Object.entries(fields ?? {}).reduce((acc, [field, item]) => {
    // TODO consider about creation parser for series and independent modules in much better way
    const parseContent = parsersByContentTypeMap[field]; //! Not perfect. EXAMPLE: field is 'embeddedModules' but content_type is 'embeddedModule'
    if (!parseContent) return acc;
    return acc.concat(parseContent(item));
  }, []);
};

export const getTotalEntryDuration = ({ cardType, fields }) => {
  if (isEmpty(cardType) || isEmpty(fields)) return null;
  let duration;
  if ([CONTENT_TYPES.INDEPENDENT_MODULE, CONTENT_TYPES.SERIES].includes(cardType)) {
    duration = fields.duration;
  } else {
    duration = fields.episodes.reduce((acc, episode) => {
      const { fields: episodeFields } = episode;
      return acc + episodeFields?.startVideo?.fields?.duration || 0 + episodeFields?.endVideo?.fields?.duration || 0;
    }, 0);
  }
  return `- ${duration} min${duration !== 1 ? 's' : ''}`;
};

export const getContentData = (cardType, fields) => {
  if (cardType === CONTENT_TYPES.INDEPENDENT_MODULE) {
    const module = parseFieldsByContentType({ fields });
    return { cardType, data: module };
  }

  const episodes =
    fields?.episodes?.reduce((acc, accItem) => {
      const list = Object.entries(accItem.fields).reduce((subList, [subListTitle, subListContent]) => {
        if ([LOCAL_CONTENT_FIELDS.START_VIDEO, LOCAL_CONTENT_FIELDS.END_VIDEO].includes(subListTitle)) {
          if (isEmpty(subListContent?.fields?.videoFile[0])) return subList;
          return subList.concat(parseVideoContent(subListContent));
        }

        if (subListTitle === LOCAL_CONTENT_FIELDS.MODULE) {
          const embeddedModules = subListContent?.flatMap(parseFieldsByContentType);
          return subList.concat(embeddedModules);
        }
        return subList;
      }, []);

      const contentCard = {
        id: accItem.sys.id,
        contentType: accItem.sys.contentType.sys.id,
        title: accItem.fields.title,
        displayName: accItem.fields.displayName || accItem.fields?.heading || accItem.fields.title,
        list,
      };
      return acc.concat(contentCard);
    }, []) || [];

  return {
    cardType,
    data: episodes,
  };
};

const getNearestVideo = ({ fields }) => {
  if (CONTENT_TYPES.VIDEO in fields) return fields[CONTENT_TYPES.VIDEO];
  if (LOCAL_CONTENT_FIELDS.START_VIDEO in fields) return fields[LOCAL_CONTENT_FIELDS.START_VIDEO];
  if (LOCAL_CONTENT_FIELDS.END_VIDEO in fields) return fields[LOCAL_CONTENT_FIELDS.END_VIDEO];
  if (LOCAL_CONTENT_FIELDS.MODULE in fields) return findDeepBy(fields[LOCAL_CONTENT_FIELDS.MODULE], getNearestVideo);
  return null;
};

export const getVideoDetails = (contentType, fields) => {
  const nearestVideo =
    contentType === CONTENT_TYPES.INDEPENDENT_MODULE
      ? getNearestVideo({ fields })
      : findDeepBy(fields?.episodes, getNearestVideo);

  return parseVideoContent(nearestVideo);
};
