import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useIsInViewport, httpClient } from '@livingsecurity/shared';
import { MenuButton, PromptSuggestions } from '../atoms';
import { ChatList } from '../molecules';
import { ChatBottomBar } from './ChatBottomBar';
import { ChatMenu } from './ChatMenu';
import { createMessage } from '../../utils';
import useConversation from '../../api/hooks/useConversation';
import usePrompts from '../../api/hooks/usePrompts';
import { saveConversation, getFeedback } from '../../api/handlers/conversations';

const SHOW_MENU = true;
const SHOW_PROMPT_SUGGESTION = true;

/*
 * App wrapper that routes users to our various application routes
 */
const ChatConversation = ({ user, integrations, embedded }) => {
  const { chatbotProvider } = useFlags();
  const inputRef = useRef();
  const scrollViewRef = useRef();
  const menuBtn = useRef();
  const stopConversationRef = useRef(false);
  const { activeConversation, userConversations, setActiveConversation, isConversationLoading, refetchConversations } =
    useConversation({
      user,
    });
  const { prompts, filterPrompts } = usePrompts({ user, integrations });
  const [messages, setMessages] = useState(activeConversation?.messages || []);
  const [value, updateValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isResponseStreaming, setResponseStreaming] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [feedback, setConvoFeedback] = useState([]);
  const hideScrollButton = useIsInViewport(scrollViewRef);

  useEffect(() => {
    handleScrollDown();
  }, []);

  useEffect(() => {
    if (activeConversation?.conversationId) {
      fetchConvoFeedback(activeConversation?.conversationId);
    }
  }, [activeConversation]);

  useEffect(() => {
    if (activeConversation?.messages) {
      setMessages(activeConversation?.messages || []);
      handleScrollDown();
    }
  }, [activeConversation]);

  const fetchConvoFeedback = useCallback(async (conversationId) => {
    const feedback = await getFeedback(conversationId);
    setConvoFeedback(feedback);
  }, []);

  const toggleSidebar = useCallback(async () => {
    setSidebarOpen((prev) => !prev);
  }, []);

  const clearConversation = useCallback(async () => {
    setMessages([]);
  }, []);

  const handleScrollDown = () => {
    setTimeout(() => {
      scrollViewRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  const saveConversationHistory = useCallback(
    async (messages) => {
      await saveConversation(user.email, user.tenant_id, { ...activeConversation, messages });
    },
    [activeConversation],
  );

  const submitMsg = useCallback(
    async (prompt) => {
      const content = prompt?.content || inputRef?.current?.value || '';
      setLoading(true);
      setResponseStreaming(true);

      updateValue('');

      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, createMessage(content, 'user', new Date())];
        if (chatbotProvider === 'AZURE') {
          chatResponse(newMessages);
        } else {
          stream(newMessages);
        }
        return [...newMessages, createMessage('', 'assistant', new Date())];
      });
    },
    [inputRef, activeConversation],
  );

  const detectIntent = useCallback(async (messages) => {
    try {
      /*
      const response = await httpClient.post('/chatbot/chat/intent', {
        messages,
        model: 'gpt-4',
      });
      console.log(response);
       */
      const response = await fetch(`${process.env.REACT_APP_NEXT_API}/chat/intent`, {
        method: 'POST',
        body: JSON.stringify({
          messages,
          model: 'gpt-4o',
        }),
      });
      if (response.status !== 200) {
        console.error(response.statusText);
      } else {
        return await response.json();
      }
      return response;
    } catch (e) {
      console.error(e);
    }
  }, []);

  const stream = useCallback(
    async (messages) => {
      let msgIntent = {};
      try {
        msgIntent = await detectIntent(messages);
      } catch (e) {
        console.error(e);
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_NEXT_API}/chat`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            messages,
            userId: user.email,
            tenantId: user.tenant_id,
            model: 'gpt-4o',
            conversationId: activeConversation.conversationId,
            ...(msgIntent || {}),
          }),
        });

        handleScrollDown();

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start(controller) {
            function push() {
              reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                push();
              });
            }

            push();
          },
        });

        const readerStream = stream.getReader();
        let responseOutput = '';

        readerStream.read().then(function processText({ done, value }) {
          if (done || stopConversationRef.current === true) {
            setResponseStreaming(false);
            handleScrollDown();
            setTimeout(() => {
              const newMessages = [...messages, { content: responseOutput, role: 'assistant' }];
              saveConversationHistory(newMessages);
            }, 1000);
            return;
          }

          let data = new TextDecoder().decode(value);

          setMessages((prevMessages) => {
            const last = prevMessages[prevMessages.length - 1] || createMessage('', 'assistant', new Date());
            responseOutput += data;
            return [...prevMessages.slice(0, -1), { ...last, content: last.content + data, role: 'assistant' }];
          });

          return readerStream.read().then(processText);
        });
        handleScrollDown();
        setLoading(false);
      } catch (e) {
        handleScrollDown();
        console.error(`Error streaming chat response: `, e);
        setLoading(false);
        setResponseStreaming(false);
        setMessages((prevMessages) => {
          const last = prevMessages[prevMessages.length - 1] || createMessage('', 'assistant', new Date());
          return [
            ...prevMessages.slice(0, -1),
            {
              ...last,
              content: last.content + 'Error - Try Again',
              role: 'assistant',
            },
          ];
        });
      }
    },
    [setLoading, setMessages, user, messages, saveConversationHistory, activeConversation, handleScrollDown],
  );

  const chatResponse = useCallback(
    async (messages) => {
      let msgIntent = {};
      try {
        msgIntent = await detectIntent(messages);
      } catch (e) {
        console.error(e);
      }

      try {
        const response = await httpClient.post('/chatbot/chat/response', {
          messages,
          userId: user.email,
          tenantId: user.tenant_id,
          conversationId: activeConversation.conversationId,
          ...(msgIntent || {}),
        });

        handleScrollDown();

        if (!response) {
          throw new Error(`Failed to get response from server`);
        } else {
          if (msgIntent?.product === 'PHISHING') {
            const exportCsv =
              messages?.[messages.length - 1]?.content?.toLowerCase().includes('csv') ||
              messages?.[messages.length - 1]?.content?.toLowerCase().includes('export');

            const msgCopy = [...messages];

            msgCopy.push({
              content: response,
              role: 'user',
            });

            const summary = await httpClient.post('/chatbot/chat/summarize', {
              messages:
                msgCopy?.map((m) => {
                  return { content: m.content, role: m.role };
                }) || [],
              model: 'gpt-35-16k',
              isExport: exportCsv,
            });

            setLoading(false);
            setResponseStreaming(false);
            handleScrollDown();
            const newMessages = [...messages, { content: summary, role: 'assistant' }];
            setMessages(newMessages);
            saveConversationHistory(newMessages);
          } else {
            setLoading(false);
            setResponseStreaming(false);
            handleScrollDown();
            const newMessages = [...messages, { content: response, role: 'assistant' }];
            setMessages(newMessages);
            saveConversationHistory(newMessages);
          }
        }
      } catch (e) {
        handleScrollDown();
        console.error(`Error streaming chat response: `, e);
        setLoading(false);
        setResponseStreaming(false);
        setMessages((prevMessages) => {
          const last = prevMessages[prevMessages.length - 1] || createMessage('', 'assistant', new Date());
          return [
            ...prevMessages.slice(0, -1),
            {
              ...last,
              content: last.content + 'Error - Try Again',
              role: 'assistant',
            },
          ];
        });
      }
    },
    [setLoading, setMessages, user, messages, saveConversationHistory, activeConversation, handleScrollDown],
  );

  const promptTrigger = useCallback(
    async (prompt, closeMenu) => {
      if (closeMenu) {
        menuBtn?.current?.click();
      }
      if (!prompt) return;
      handleScrollDown();
      return await submitMsg(prompt);
    },
    [submitMsg],
  );

  const regenerateFromMsg = useCallback(
    (index) => {
      const newMessages = messages.slice(0, index);
      const prompt = newMessages[index - 1]?.content;

      setLoading(true);
      setResponseStreaming(true);

      setMessages(() => {
        if (chatbotProvider === 'AZURE') {
          chatResponse(newMessages, prompt);
        } else {
          stream(newMessages, prompt);
        }
        return [...newMessages, createMessage('', 'assistant', new Date())];
      });
    },
    [messages, setMessages, stream],
  );

  return (
    <>
      <div
        className="bg-gradient-to-b dark:from-base-100/50 dark:to-neutral flex relative"
        style={{ overflow: !messages.length ? 'hidden' : 'initial' }}
      >
        {SHOW_MENU && (
          <ChatMenu
            sidebarOpen={sidebarOpen}
            toggleSidebar={() => menuBtn?.current?.click()}
            embedded={embedded}
            prompts={prompts}
            filterPrompts={filterPrompts}
            promptTrigger={promptTrigger}
            userConversations={userConversations}
            setActiveConversation={setActiveConversation}
            refetchConversations={refetchConversations}
          />
        )}
        <div
          className={`${embedded || !sidebarOpen ? 'w-full' : 'w-3/4'} relative flex flex-col overflow-hidden`}
          style={{ height: 'calc(100vh - 58px)' }}
        >
          {SHOW_MENU && <MenuButton menuBtn={menuBtn} toggleSidebar={toggleSidebar} />}
          <ChatList
            conversationId={activeConversation.conversationId}
            messages={messages}
            scrollViewRef={scrollViewRef}
            user={user}
            loading={loading}
            isConversationLoading={isConversationLoading}
            embedded={embedded}
            feedback={feedback}
            regenerateFromMsg={regenerateFromMsg}
          />
          {SHOW_PROMPT_SUGGESTION && !messages.length && !isConversationLoading && (
            <PromptSuggestions promptTrigger={promptTrigger} />
          )}
          <ChatBottomBar
            updateValue={updateValue}
            submitMsg={submitMsg}
            prompts={prompts}
            isResponseStreaming={isResponseStreaming}
            hideScrollButton={hideScrollButton}
            handleScrollDown={handleScrollDown}
            stopConversationRef={stopConversationRef}
            inputRef={inputRef}
            clearConversation={clearConversation}
            embedded={embedded}
            loading={loading}
            content={value}
            sidebarOpen={sidebarOpen}
          />
        </div>
      </div>
    </>
  );
};

export default ChatConversation;
