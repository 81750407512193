/* eslint-disable no-unused-expressions */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';
import moment from 'moment/moment';
import {
  HubspotChat,
  Loader,
  LOCATION_KEY,
  setToLS,
  URLS,
  TEAMS_URLS,
  useAuth0,
  useInitializationService,
  useSearchParams,
  useTrackInteraction,
  EVENTS,
} from '@livingsecurity/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AuthEntity } from '_entities';
import LogoutWarning from '../blocks/LogoutWarning';

const { getCompanyInfo } = AuthEntity.selectors;

const PrivateRoute = ({ component: Component, path, groups, ...rest }) => {
  const {
    loading,
    isAuthenticated,
    loginWithRedirect,
    isUserHasRights,
    user,
    userGroups,
    locale,
    isImpersonate,
    isLsAdmin,
    isReseller,
    isCompanyAdmin,
    checkAuthExpiration,
    logout,
  } = useAuth0();
  const companyInfo = useSelector(getCompanyInfo);
  const trainingIgnoreRoutes = useMemo(
    () => [
      URLS.phishing,
      URLS.phishingDemo,
      URLS.dashboard,
      URLS.player,
      URLS.profile,
      URLS.companies,
      URLS.lsAdmins,
      URLS.support,
      URLS.chatbot,
      URLS.userManagement,
      '/content-team',
    ],
    [],
  );
  const teamsIgnoreRoutes = useMemo(
    () => [
      TEAMS_URLS.demo,
      TEAMS_URLS.company,
      TEAMS_URLS.companies,
      TEAMS_URLS.lsAdmins,
      TEAMS_URLS.storylines,
      TEAMS_URLS.campaign,
      TEAMS_URLS.teamsBooking,
      TEAMS_URLS.teamsCancelBooking,
      TEAMS_URLS.teamsEditBooking,
      TEAMS_URLS.teamsBookingResult,
      TEAMS_URLS.teamsBookingCancel,
      TEAMS_URLS.teamsLeaderboard,
    ],
    [],
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const { showHubspotWidget } = useInitializationService({ companyInfo });
  const { pathname } = useLocation();
  const { searchParams, setSearchParams } = useSearchParams();
  const hubspotWidget = window.HubSpotConversations?.widget;
  const flags = useFlags();
  const { track } = useTrackInteraction();

  useEffect(() => {
    if (
      !searchParams?.tenantId &&
      user?.tenant_id &&
      flags?.tenantRegionUrl &&
      !(pathname?.includes('/teams') || pathname?.includes('/phishing'))
    ) {
      setSearchParams((current) => {
        // Remove undefined values
        const params = Object.keys(current).reduce((acc, key) => {
          const _acc = acc;
          if (current[key] !== undefined && current[key] !== 'undefined') _acc[key] = current[key];
          return _acc;
        }, {});
        return {
          ...params,
          tenantId: user?.tenant_id,
          region: companyInfo?.region || 'US',
        };
      });
    }
  }, [searchParams, user, companyInfo, flags, setSearchParams, pathname]);

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, pathname]);

  useEffect(() => {
    if (!showHubspotWidget) {
      hubspotWidget?.remove();
    }
  }, [showHubspotWidget, hubspotWidget]);

  useEffect(() => {
    if (pathname.includes(URLS.teamsBase) && !teamsIgnoreRoutes.includes(pathname)) {
      setToLS(LOCATION_KEY.TEAMS_PATH, pathname);
    } else if (pathname.includes(URLS.unify) && !pathname.includes('/demo') && !pathname.includes('/settings/api')) {
      setToLS(LOCATION_KEY.UNIFY_PATH, pathname);
    } else if (
      !trainingIgnoreRoutes.includes(pathname) &&
      !teamsIgnoreRoutes.includes(pathname) &&
      !pathname.includes('/companies/') &&
      !pathname.includes('/player') &&
      !pathname.includes('/assignment') &&
      !pathname.includes('/admin/api-auth') &&
      !pathname.includes('/demo')
    ) {
      setToLS(LOCATION_KEY.TRAINING_PATH, pathname);
    }
  }, [pathname, trainingIgnoreRoutes, teamsIgnoreRoutes]);

  const initializeVitally = useCallback(async () => {
    if (process.env.REACT_APP_NEXT_API && user.email && (user.tenant_id || companyInfo?.id)) {
      try {
        await axios.post(`${process.env.REACT_APP_NEXT_API}/metric/account`, {
          accountId: user.tenant_id || companyInfo?.id,
          userId: user.email || user.sub,
          accountTraits: {
            id: user.tenant_id || companyInfo?.id,
            name: companyInfo?.name,
            isExpired: !!companyInfo?.is_expired,
            createdAt: companyInfo?.created_at,
            preferredTimezone: companyInfo?.preferred_timezone,
            pointOfContact: companyInfo?.point_of_contact,
            contentSubscriptions: companyInfo?.subscriptions,
            trainingLicenseStartDate: companyInfo?.license_start_date,
            trainingLicenseExpirationDate: companyInfo?.license_expiration_date,
            trainingLMSGateway: !!companyInfo?.lms_gateway,
            trainingRequiredPassingGrade: !!companyInfo?.required_passing_grade,
            trainingPassingGrade: companyInfo?.passing_grade,
            trainingPasswordlessAssignments: companyInfo?.passwordless_assignments,
            trainingUsersCount: companyInfo?.total_users,
            trainingUsersCompleted: companyInfo?.completed_users_during_contract_period,
            SSOIntegration: companyInfo?.sso_integration,
            platformStatus: companyInfo?.site_status,
            trainingAssignmentTime: companyInfo?.action_execution_time,
            securityAdmins: JSON.stringify(companyInfo?.security_admins || []),
          },
          userTraits: {
            id: user.email || user.sub,
            name: user.nickname || user.email,
            email: user.email,
            roles: userGroups,
            language: locale,
          }
        });

        datadogLogs.logger.info(`initializeVitally: ${JSON.stringify({
          accountId: user.tenant_id || companyInfo?.id,
          userId: user.email || user.sub,
          accountTraits: {
            id: user.tenant_id || companyInfo?.id,
            name: companyInfo?.name,
          },
        })}`);
        track(EVENTS.USER_LOGIN, {
          userId: user.email || user.sub,
          email: user.email,
          company: {
            id: user.tenant_id || companyInfo?.id,
            name: companyInfo?.name,
          },
        });
      } catch (e) {
        datadogLogs.logger.error(e);
        console.error(e);
      }
    }
  }, [user, companyInfo, locale, userGroups, track]);

  useEffect(() => {
    if (user?.email && isAuthenticated) {
      datadogLogs.onReady(() => {
        datadogLogs.setGlobalContextProperty('companyId', user.tenant_id);
        datadogLogs.setGlobalContextProperty('company', companyInfo?.name);
        datadogLogs.setGlobalContextProperty('usr.email', user.email);
        datadogLogs.setGlobalContextProperty('usr.id', user.sub);
        datadogLogs.setGlobalContextProperty('usr.name', user.nickname || user.email);
        datadogLogs.setGlobalContextProperty('userGroups', userGroups);
        datadogLogs.setGlobalContextProperty('locale', locale);
        datadogLogs.setGlobalContextProperty('isImpersonate', isImpersonate);
      });

      // Initializing here and not in useInitializationService so Participants never get tracked
      if (flags.enableVitallyTracking && isCompanyAdmin && user?.tenant_id && !isLsAdmin && !isReseller && !isInitialized) {
        datadogLogs.logger.info(`Vitally initialized ${user.email} from ${user.tenant_id}`);
        setIsInitialized(true);
        initializeVitally();
      }
    }
  }, [isAuthenticated, track, user, user?.email, companyInfo, isImpersonate, locale, userGroups, isLsAdmin, isCompanyAdmin, isReseller, isInitialized, initializeVitally]);

  // Token Expiration Event Loop (for non-LS_ADMIN)
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isLsAdmin) {
        const authExp = checkAuthExpiration();
        if (process.env.REACT_APP_DEBUG) {
          console.log('Auth token expires at:', moment(authExp, 'x').format());
        }
        // If token is expired, logout the user
        if (authExp && moment().isAfter(moment(authExp, 'x'))) {
          logout();
        }
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [checkAuthExpiration, logout, isLsAdmin]);

  const render = (props) => (
    <>
      <HubspotChat />
      <Component user={user || {}} flags={flags || {}} {...props} />{' '}
    </>
  );

  const isValidUser = isAuthenticated === true && isUserHasRights(groups);

  if (isValidUser && isEmpty(flags)) {
    return <Loader coverElement />;
  }

  return isValidUser ? (
    <>
      <Route path={path} render={render} {...rest} />
      <LogoutWarning teamsRoute={pathname.includes(URLS.teamsBase)} phishingRoute={pathname.includes(URLS.phishing)} />
    </>
  ) : (
    <Redirect to="/" />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  groups: PropTypes.arrayOf(PropTypes.string),
};

PrivateRoute.defaultProps = {
  groups: [],
};

export default PrivateRoute;
