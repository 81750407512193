import React, { useState, useCallback, useEffect } from 'react';
import MarkdownIt from 'markdown-it';
import LoadingDots from './LoadingDots';

const mdParser = new MarkdownIt({
  html: true,
});

const defaultRender =
  mdParser.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

mdParser.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex('target');

  if (aIndex < 0) {
    tokens[idx].attrPush(['target', '_blank']); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = '_blank'; // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

const SHOW_BUTTONS = true;

/*
 * App wrapper that routes users to our various application routes
 */
export const ChatBubble = ({
  message,
  avatarUrl,
  timestamp,
  position,
  botLoading,
  feedback,
  embedded,
  regenerateFromMsg,
  leaveFeedback,
}) => {
  const [isExpanded, toggleExpanded] = useState(false);
  const [msgFeedback, setMsgFeedback] = useState(false);

  useEffect(() => {
    setMsgFeedback(feedback ? feedback.feedback : false);
  }, [feedback, setMsgFeedback]);

  const toggleMessageDetails = useCallback(() => {
    toggleExpanded(!isExpanded);
  }, [isExpanded]);

  const renderMarkdownAsHTML = useCallback((text = '') => {
    return mdParser.render(text);
  }, []);

  const copyText = () => {
    navigator.clipboard.writeText(message.trim());
  };

  return (
    <li
      className={`chat chat-${position} flex items-end ${position === 'end' ? 'ml-auto' : 'mr-auto'} gap-x-2`}
      style={{
        maxWidth: embedded ? '100% !important' : '80%',
      }}
    >
      {position === 'start' && (
        <div className={`min-w-fit mb-4`}>
          <img
            className={`chat-image avatar flex-shrink-0 rounded-full select-none ${
              embedded ? 'h-[36px] w-[40px]' : 'h-[50px] w-[55px]'
            }`}
            src={avatarUrl}
            alt="Bot Avatar"
          />
        </div>
      )}

      <div className={`flex flex-col w-full items-${position}`}>
        <div
          className={`chat-bubble ${
            position === 'end'
              ? 'bg-primary/60 dark:bg-primary text-black'
              : 'bg-white text-black dark:text-white dark:bg-neutral'
          } ${position === 'start' && botLoading ? '' : 'py-2 px-3 space-y-1'} text-md shadow-lg`}
          style={{ maxWidth: 'calc(100% - 60px)' }}
        >
          {position === 'start' && botLoading ? (
            <LoadingDots />
          ) : (
            <div
              className={`chat-text-inner ${position === 'start' ? 'agent-text' : 'user-text'}`}
              dangerouslySetInnerHTML={{ __html: renderMarkdownAsHTML(message) }}
            />
          )}
        </div>
        <div className={`chat-footer flex flex-col mt-[2px] mx-1 w-4/5 items-${position} select-none`}>
          {isExpanded && (
            <div className={`flex opacity-20 text-neutral dark:text-white text-xs py-1 select-none`}>{timestamp}</div>
          )}
          {position === 'start' && SHOW_BUTTONS && (
            <div className="h-4">
              {!botLoading && (
                <div className="sm:flex sm:justify-between">
                  <div>
                    <button
                      type="button"
                      className="inline-flex justify-center items-center h-4 w-4 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-200 focus:ring-offset-1 transition-all text-sm hover:bg-slate-800 hover:text-white hover:border-gray-900 focus:ring-gray-900 focus:ring-offset-gray-800"
                      onClick={() => {
                        setMsgFeedback('POSITIVE');
                        leaveFeedback('POSITIVE');
                      }}
                    >
                      {msgFeedback === 'POSITIVE' ? (
                        <i className="fas fa-thumbs-up text-[15px]" />
                      ) : (
                        <i className="fal fa-thumbs-up text-[15px]" />
                      )}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center items-center h-4 w-4 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-200 focus:ring-offset-1 transition-all text-sm hover:bg-slate-800 hover:text-white hover:border-gray-900 focus:ring-gray-900 focus:ring-offset-gray-800"
                      onClick={() => {
                        setMsgFeedback('NEGATIVE');
                        leaveFeedback('NEGATIVE');
                      }}
                    >
                      {msgFeedback === 'NEGATIVE' ? (
                        <i className="fas fa-thumbs-down text-[15px]" />
                      ) : (
                        <i className="fal fa-thumbs-down text-[15px]" />
                      )}
                    </button>
                    {/* Copy */}
                    <button
                      type="button"
                      className="ml-2 inline-flex justify-center items-center h-4 w-4 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-200 focus:ring-offset-1 transition-all text-sm hover:bg-slate-800 hover:text-white hover:border-gray-900 focus:ring-gray-900 focus:ring-offset-gray-800"
                      onClick={copyText}
                    >
                      <i className="fal fa-copy text-[15px]" />
                    </button>
                    {false && (
                      // Share
                      <button
                        type="button"
                        className="ml-2 inline-flex justify-center items-center h-4 w-4 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 transition-all text-sm hover:bg-slate-800 hover:text-white hover:border-gray-900 focus:ring-gray-900 focus:ring-offset-gray-800"
                      >
                        <i className="fas fa-share text-[15px]" />
                      </button>
                    )}
                  </div>
                  <div className="mt-1 sm:mt-0">
                    {/* Regenerate */}
                    <button
                      type="button"
                      className="inline-flex justify-center items-center h-4 w-4 rounded-full border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-200 focus:ring-offset-1 transition-all text-sm hover:bg-slate-800 hover:text-white hover:border-gray-900 focus:ring-gray-900 focus:ring-offset-gray-800"
                      onClick={regenerateFromMsg}
                    >
                      <i className="fal fa-redo text-[15px]" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {position === 'end' && (
        <div>
          <img
            className={`chat-image avatar flex-shrink-0 rounded-full select-none ${
              embedded ? 'h-[36px] w-[40px]' : 'h-[50px] w-[55px]'
            }`}
            src={avatarUrl}
            alt="User Avatar"
          />
        </div>
      )}
    </li>
  );
};

ChatBubble.defaultProps = {
  message: 'Testing',
  avatarUrl: '',
  timestamp: '2023-07-18',
  position: 'start',
};
