import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ErrorBoundary, ErrorReload, Iframe, Loader, TEAMS_URLS, useAuth0, useLSTeams } from '@livingsecurity/shared';
import { AuthorizedLayout } from 'components';

const TeamsManagement = ({ history }) => {
  const { locale } = useAuth0();
  const { loading, hasAccess, getEmbeddedUrl } = useLSTeams();
  const [iframeLoading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (window.location?.href?.includes('livingsecurity.com')) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/domain
      window.document.domain = 'livingsecurity.com';
    }
  }, []);

  useEffect(() => {
    if (!loading && !iframeLoading && !hasAccess && window.location?.pathname !== TEAMS_URLS.demo) {
      history.replace(TEAMS_URLS.demo);
    }
  }, [hasAccess, loading, history, iframeLoading]);

  const onLoad = () => {
    if (!loading) {
      setLoading(false);
    }
  };

  const onError = useCallback((error) => {
    /* eslint no-console: "off" */
    console.error(error);
    setError(true);
  }, []);

  const refreshPage = useCallback(() => {
    window.location.reload(true);
  }, []);

  if (!locale) {
    return null;
  }

  return (
    <AuthorizedLayout app="TEAMS" withBottomPaddingForHubspot>
      <>
        {!hasError && (loading || iframeLoading) && <Loader />}
        {hasError ? (
          <ErrorReload callback={refreshPage} />
        ) : (
          <Router history={history}>
            <Switch>
              {Object.values(TEAMS_URLS).map((view, index) => {
                return (
                  <Route path={view} key={index}>
                    <ErrorBoundary scope="teams-management">
                      <Iframe
                        id="teams-embed-frame"
                        allow="clipboard-read; clipboard-write *;"
                        className="embedded-iframe"
                        data-testid="teams-embed-frame"
                        title="Living Security Teams"
                        src={getEmbeddedUrl(view, window.location.href)}
                        onLoad={onLoad}
                        onError={onError}
                        style={{
                          opacity: `${iframeLoading ? 0 : 1}`,
                          transition: 'opacity 1s',
                        }}
                      />
                    </ErrorBoundary>
                  </Route>
                );
              })}
              <Redirect to={TEAMS_URLS.scheduling} />
            </Switch>
          </Router>
        )}
      </>
    </AuthorizedLayout>
  );
};

TeamsManagement.propTypes = {
  history: PropTypes.any.isRequired,
};

export default TeamsManagement;
