import { useState } from 'react';
import { useQuery } from 'react-query';
import { camelCase, sortBy } from 'lodash';

import { httpClient, clientContentful } from '../lib';
import { INITIAL_LS_SERIES } from '../_constants';
import { getTotalEntryDuration, getContentData } from '../utils/custom/contentfulUtils';

export const getCampaignTimeline = ({ contentfulId }) => httpClient.get(`/campaigns/content/${contentfulId}/timeline`);

export const getTrainingContentFromContentful = ({ contentfulIds }) =>
  clientContentful.getEntries({
    'sys.id[in]': contentfulIds.toString(),
    include: 10,
  });

export const useCampaignTimeline = ({ contentfulId }) => {
  const { data, isLoading } = useQuery(
    ['campaignTimeline', contentfulId],
    () => getCampaignTimeline({ contentfulId }),
    {
      enabled: !!contentfulId,
      // onSuccess: (data) => {},
    },
  );

  const timelineData = data?.map((item) => ({
    id: item?.id,
    actionType: item?.action_type,
    contentItemIds: item?.content_item_ids,
    assignDay: item?.assign_day,
    ...(item?.action_type === 'training_content' ? { trainingType: item?.training_type, dueDay: item?.due_day } : {}),
    nickname: item?.nickname,
  }));

  return { timelineData, timelineDataIsLoading: isLoading };
};

export const useTimelineTrainingContent = ({ contentfulIds }) => {
  const [trainingContentData, setTrainingContentData] = useState([]);

  const { isLoading } = useQuery(
    ['timelineTrainingContent', contentfulIds],
    () => getTrainingContentFromContentful({ contentfulIds }),
    {
      enabled: !!contentfulIds,
      onSuccess: (successfulData) => {
        const sortedData = sortBy(
          successfulData?.items?.map(({ sys, fields }) => ({
            id: sys?.id,
            contentType: sys?.contentType?.sys?.id,
            ...(fields?.type?.length > 0 ? { type: fields?.type?.map((type) => camelCase(type)) } : {}),
            title: fields?.title,
            description: fields?.description,
            difficulty: fields?.difficulty,
            duration: fields?.duration,
            categories: fields?.categories,
            ...(sys?.contentType?.sys?.id !== INITIAL_LS_SERIES.id
              ? { sectionDuration: getTotalEntryDuration({ cardType: sys.contentType.sys.id, fields }) }
              : {}),
            contentData: getContentData(sys.contentType.sys.id, fields),
            contentLabel: sys.contentType.sys.id === INITIAL_LS_SERIES.id ? 'videoCard.episodes' : 'videoCard.imd',
          })),
          (item) => contentfulIds.indexOf(item.id),
        );

        setTrainingContentData(sortedData);
      },
    },
  );

  return { trainingContentData, isLoading };
};
