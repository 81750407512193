import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useAuth0,
  getUnifyUrl,
  ErrorBoundary,
  USER_GROUPS,
  Iframe,
} from '@livingsecurity/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AuthorizedLayout } from 'components';

const ApiAuthentication = ({ history }) => {
  const { isUserHasRights } = useAuth0();
  const { enableEmbeddedUnify } = useFlags();
  const hasAccess =
    isUserHasRights([
      USER_GROUPS.LS_ADMIN,
    ]);

  useEffect(() => {
    if (window.location?.href?.includes('livingsecurity.com')) {
      window.document.domain = 'livingsecurity.com';
    }
  }, []);

  useEffect(() => {
    if (!hasAccess) {
      history.replace('/');
    }
  }, [hasAccess, history]);

  return (
    <>
      <AuthorizedLayout app="API_AUTHENTICATION" hideSidebar withBottomPaddingForHubspot>
        <ErrorBoundary scope="api-authentication">
          <Iframe
            id="platform-api-authentication-frame"
            allow="clipboard-read; clipboard-write *;"
            className="embedded-iframe"
            data-testid="platform-api-authentication"
            title="Living Security API Authentication"
            src={`${getUnifyUrl()}/settings/api`}
          />
        </ErrorBoundary>
      </AuthorizedLayout>
    </>
  );
};

ApiAuthentication.propTypes = {
  history: PropTypes.any.isRequired,
};

export default ApiAuthentication;
