import { httpClient } from '@livingsecurity/shared';

// Query Keys
export const ChatConversations = {
  list: () => [{ name: 'chatbot.conversations.list' }],
  get: ({ userId }) => [{ name: `chatbot.conversations.get.${userId}` }],
};

// Handlers
export const fetchUsersConversations = (email, tenantId) => {
  if (!email) return;
  return httpClient.post('/chatbot/chat/history', { userId: email, tenantId });
};

export const saveConversation = async (email, tenantId, conversation) => {
  if (!conversation) return;
  return httpClient.post('/chatbot/chat/save', { userId: email, tenantId, ...conversation });
};

export const getFeedback = async (conversationId) => {
  if (!conversationId) return;
  return httpClient.get(`/chatbot/chat/feedback?conversationId=${conversationId}`);
};

export const setFeedback = async (params) => {
  if (!params.conversationId || !params.tenantId) return;
  return httpClient.post(`/chatbot/chat/feedback`, params);
};
