import React, { useState, useEffect, useRef, useCallback } from 'react';
import { PromptList, PromptVariableModal } from '../atoms';
import { useTranslation } from 'react-i18next';

const MAX_INPUT_HEIGHT = 260;
const ALLOW_PROMPTS = false;
const ALLOW_ATTACHMENT = false;
const ALLOW_MIC = false;
const ALLOW_SCROLL = true;

export const ChatBottomBar = ({
  updateValue,
  content,
  submitMsg,
  prompts,
  isResponseStreaming,
  hideScrollButton,
  handleScrollDown,
  stopConversationRef,
  inputRef,
  embedded,
  loading,
}) => {
  const { t } = useTranslation('chatbot');
  const activePlugin = localStorage.getItem('CHAT_ACTIVE_PLUGIN') || null;
  const [isTyping, setIsTyping] = useState(false);
  const [showPromptList, setShowPromptList] = useState(false);
  const [activePromptIndex, setActivePromptIndex] = useState(0);
  const [promptInputValue, setPromptInputValue] = useState('');
  const [variables, setVariables] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showPluginSelect, setShowPluginSelect] = useState(false);
  const [plugin, setPlugin] = useState(activePlugin && activePlugin !== 'undefined' ? JSON.parse(activePlugin) : null);

  const promptListRef = useRef(null);

  const filteredPrompts = prompts.filter((prompt) =>
    prompt.name.toLowerCase().includes(promptInputValue.toLowerCase()),
  );

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.style.height = 'inherit';
      inputRef.current.style.height = `${inputRef.current?.scrollHeight}px`;
      inputRef.current.style.overflow = `${inputRef?.current?.scrollHeight > MAX_INPUT_HEIGHT ? 'auto' : 'hidden'}`;
    }
  }, [content]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (promptListRef.current && !promptListRef.current.contains(e.target)) {
        setShowPromptList(false);
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (promptListRef.current) {
      promptListRef.current.scrollTop = activePromptIndex * 30;
    }
  }, [activePromptIndex]);

  const handleChange = (e) => {
    const value = e.target.value;
    updateValue(value);
    updatePromptListVisibility(value);
  };

  const handleSend = () => {
    if (isResponseStreaming) {
      return;
    }

    if (!content) {
      alert(t('Please enter a message'));
      return;
    }

    submitMsg({ content }, plugin);
    setIsTyping(false);
  };

  const handleKeyDown = (e) => {
    if (showPromptList) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActivePromptIndex((prevIndex) => (prevIndex < prompts.length - 1 ? prevIndex + 1 : prevIndex));
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActivePromptIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      } else if (e.key === 'Tab') {
        e.preventDefault();
        setActivePromptIndex((prevIndex) => (prevIndex < prompts.length - 1 ? prevIndex + 1 : 0));
      } else if (e.key === 'Enter') {
        e.preventDefault();
        handleInitModal();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setShowPromptList(false);
      } else {
        setActivePromptIndex(0);
      }
    } else if (e.key === 'Enter' && !isMobile() && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    } else if (e.key === '/' && e.metaKey) {
      e.preventDefault();
      setShowPluginSelect(!showPluginSelect);
    } else if (!isTyping) {
      setIsTyping(true);
    }
  };

  const isMobile = () => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i;
    return mobileRegex.test(userAgent);
  };

  const handleInitModal = () => {
    const selectedPrompt = filteredPrompts[activePromptIndex];
    if (selectedPrompt) {
      updateValue((prevContent) => {
        const newContent = prevContent?.replace(/\/\w*$/, selectedPrompt.content);
        return newContent;
      });
      handlePromptSelect(selectedPrompt);
    }
    setShowPromptList(false);
  };

  const handlePromptSelect = (prompt) => {
    const parsedVariables = parseVariables(prompt.content);
    setVariables(parsedVariables);

    if (parsedVariables?.length) {
      setIsModalVisible(true);
    } else {
      updateValue((prevContent) => {
        // What does this regex do?
        const updatedContent = prevContent?.replace(/\/\w*$/, prompt.content);
        return updatedContent;
      });
      updatePromptListVisibility(prompt.content);
    }
  };

  const updatePromptListVisibility = useCallback((text) => {
    const match = text.match(/\/\w*$/);

    if (match) {
      setShowPromptList(true);
      setPromptInputValue(match[0].slice(1));
    } else {
      setShowPromptList(false);
      setPromptInputValue('');
    }
  }, []);

  const parseVariables = (content) => {
    const regex = /{{(.*?)}}/g;
    const foundVariables = [];
    let match;

    while ((match = regex.exec(content)) !== null) {
      foundVariables.push(match[1]);
    }

    return foundVariables;
  };

  const handleSubmit = (updatedVariables) => {
    const newContent = content?.replace(/{{(.*?)}}/g, (match, variable) => {
      const index = variables.indexOf(variable);
      return updatedVariables[index];
    });

    updateValue(newContent);

    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleStopConversation = () => {
    stopConversationRef.current = true;
    setTimeout(() => {
      stopConversationRef.current = false;
    }, 1000);
  };

  return (
    <>
      <div className="absolute bottom-0 w-full bg-accent dark:bg-neutral/95 backdrop-blur-lg z-10">
        <footer
          className={`max-w-full mx-auto py-1 px-2 sm:px-2 lg:px-0 ${embedded ? 'lg:max-w-[95%]' : 'lg:max-w-[80%]'}`}
        >
          {isResponseStreaming && !loading && (
            <div className="absolute top-[-34px] inset-0">
              <div className="flex justify-between items-center mb-1">
                <button
                  type="button"
                  className="py-1.5 px-2 inline-flex justify-center items-center gap-2 mx-auto rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-primary transition-all text-xs dark:bg-neutral dark:hover:bg-slate-800 dark:border-white/30 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                  onClick={handleStopConversation}
                >
                  <svg
                    className="w-2 h-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z" />
                  </svg>
                  {t('stop-generating')}
                </button>
              </div>
            </div>
          )}
          <div className="relative">
            <textarea
              ref={inputRef}
              className="p-2 pr-[50px] block w-full border-gray-200/60 text-label rounded-md focus:border-primary focus:ring-primary dark:bg-neutral dark:border-white/30 dark:text-gray-400 placeholder:italic"
              placeholder={t('placeholder')}
              value={content}
              rows={1}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              style={{
                resize: 'none',
                bottom: `${inputRef?.current?.scrollHeight}px`,
                maxHeight: `${MAX_INPUT_HEIGHT}px`,
                overflow: `${inputRef.current && inputRef.current.scrollHeight > MAX_INPUT_HEIGHT ? 'auto' : 'hidden'}`,
              }}
            />
            <div
              className="absolute right-[1px] bottom-[4px] rounded-b-md bg-white dark:bg-neutral"
              style={{ padding: '5px 8px' }}
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  {ALLOW_PROMPTS && (
                    <button
                      type="button"
                      className="btn btn-neutral btn-xs inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-md text-gray-500 hover:text-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:text-primary"
                      onClick={() => setShowPromptList(!showPromptList)}
                    >
                      <svg
                        className="h-2.5 w-2.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M11.354 4.646a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708l6-6a.5.5 0 0 1 .708 0z" />
                      </svg>
                    </button>
                  )}
                  {ALLOW_ATTACHMENT && (
                    <button
                      type="button"
                      className="btn btn-neutral btn-xs inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-md text-gray-500 hover:text-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:text-primary"
                    >
                      <svg
                        className="h-2.5 w-2.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="flex items-center gap-x-1">
                  {ALLOW_MIC && (
                    <button
                      type="button"
                      className="btn btn-neutral btn-xs inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-md text-gray-500 hover:text-primary focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:text-primary"
                    >
                      <svg
                        className="h-2.5 w-2.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                        <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                      </svg>
                    </button>
                  )}
                  <button
                    type="button"
                    className={`btn btn-primary btn-xs inline-flex flex-shrink-0 justify-center items-center h-5 w-5 rounded-md text-white hover:text-white/60 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary transition-all dark:hover:text-white/60 ${
                      !content?.length && 'opacity-50'
                    }`}
                    disabled={loading || !content?.length}
                    onClick={submitMsg}
                  >
                    <svg
                      className="h-2.5 w-2.5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {showPromptList && filteredPrompts.length > 0 && (
          <div className="absolute bottom-9 w-full">
            <PromptList
              activePromptIndex={activePromptIndex}
              prompts={filteredPrompts}
              onSelect={handleInitModal}
              onMouseOver={setActivePromptIndex}
              promptListRef={promptListRef}
            />
          </div>
        )}
        {ALLOW_SCROLL && !hideScrollButton && !isTyping && (
          <div id="scroll-bottom" className={`absolute top-[-15px] ${embedded ? 'right-[-10px]' : 'right-2'} `}>
            <button
              className={`btn btn-neutral/10 dark:btn-neutral opacity-95 hover:opacity-100 btn-circle ${
                embedded ? 'right-0 max-h-fit h-fit max-w-fit min-h-[2rem]' : 'right-2'
              }`}
              onClick={handleScrollDown}
            >
              <i className="fas fa-arrow-alt-down font-[24px] p-1" />
            </button>
          </div>
        )}
      </div>
      {isModalVisible && (
        <PromptVariableModal
          prompt={filteredPrompts[activePromptIndex]}
          variables={variables}
          embedded={embedded}
          onSubmit={handleSubmit}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </>
  );
};
